import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { WellnessPreview } from './WellnessPreview';
import { StatsPreview } from './StatsPreview';
import { TrackingFormPreview } from './TrackingFormPreview';
import { HealthFormPreview } from './HealthFormPreview';
import { LearningPreview } from './LearningPreview';
import { PhoneMockup } from './PhoneMockup';
import { 
  HeartIcon, 
  SparklesIcon, 
  MoonIcon, 
  ClockIcon,
  StarIcon,
  BeakerIcon
} from '@heroicons/react/24/outline';

// Add FloatingElements component
function FloatingElements() {
  return (
    <>
      {/* Hero Section Elements - Smaller & fewer on mobile */}
      <motion.div 
        className="absolute top-32 left-[10%] text-accent-lavender/10"
        animate={{ 
          y: [-10, 10, -10],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">👶</span>
      </motion.div>

      <motion.div 
        className="absolute top-40 right-[15%] text-accent-mint/10"
        animate={{ 
          y: [10, -10, 10],
          rotate: [5, -5, 5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-xl md:text-3xl">🍼</span>
      </motion.div>

      <motion.div 
        className="absolute top-[60%] left-[20%] text-accent-mint/10"
        animate={{ 
          y: [-15, 15, -15],
          x: [-5, 5, -5]
        }}
        transition={{ 
          duration: 10,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">🌙</span>
      </motion.div>

      <motion.div 
        className="absolute top-[65%] right-[25%] text-accent-peach/10"
        animate={{ 
          y: [15, -15, 15],
          x: [5, -5, 5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <ClockIcon className="w-8 h-8 md:w-14 md:h-14" />
      </motion.div>

      <motion.div 
        className="absolute bottom-[20%] left-[15%] text-accent-lavender/10"
        animate={{ 
          scale: [1, 1.15, 1],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <SparklesIcon className="w-12 h-12" />
      </motion.div>
    </>
  );
}

type FeatureTab = 'tracking' | 'wellness' | 'learning' | 'health' | 'stats';

export function FeatureShowcase() {
  const [activeTab, setActiveTab] = useState<FeatureTab>('stats');

  const features = [
    {
      id: 'stats' as const,
      label: 'Dashboard',
      color: 'lavender'
    },
    {
      id: 'tracking' as const,
      label: 'Baby Tracking',
      color: 'peach'
    },
    {
      id: 'wellness' as const,
      label: 'Wellness',
      color: 'mint'
    },
    {
      id: 'learning' as const,
      label: 'Ask AI',
      color: 'gold'
    },
    {
      id: 'health' as const,
      label: 'Health',
      color: 'rose'
    }
  ];

  return (
    <div className="relative py-12 sm:py-20 px-4 overflow-hidden">
      {/* Rich Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Large Gradient Orbs */}
        <div className="absolute -top-1/4 left-1/4 w-[800px] h-[800px] bg-accent-lavender/5 rounded-full blur-3xl animate-pulse-slow" />
        <div className="absolute -bottom-1/4 right-1/4 w-[600px] h-[600px] bg-accent-mint/5 rounded-full blur-3xl animate-pulse-slower" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[900px] h-[900px] bg-accent-rose/5 rounded-full blur-3xl animate-pulse" />
        
        {/* Animated Particles */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_rgba(255,255,255,0.03)_1px,_transparent_1px)] bg-[length:32px_32px]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_rgba(255,255,255,0.02)_1px,_transparent_1px)] bg-[length:24px_24px] animate-drift" />
        </div>
        
        {/* Subtle Grid Lines */}
        <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.02)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.02)_1px,transparent_1px)] bg-[size:64px_64px]" />
        
        {/* Dynamic Glow Effects */}
        <div className="absolute inset-0">
          <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-accent-lavender/20 rounded-full blur-2xl animate-glow" />
          <div className="absolute bottom-1/4 right-1/4 w-32 h-32 bg-accent-mint/20 rounded-full blur-2xl animate-glow-delayed" />
        </div>
        
        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-gradient-to-b from-dark-bg via-transparent to-dark-bg opacity-90" />
        <div className="absolute inset-0 bg-gradient-to-r from-dark-bg via-transparent to-dark-bg opacity-90" />
        
        {/* Add floating elements */}
        <FloatingElements />
      </div>

      <div className="relative max-w-6xl mx-auto">
        {/* Header */}
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-lg sm:text-2xl font-medium sm:font-bold mb-2 sm:mb-4 
                      text-gray-300 sm:bg-gradient-to-r from-accent-lavender via-accent-mint to-accent-rose sm:bg-clip-text sm:text-transparent">
            See It In Action
          </h2>
        </div>

        {/* Feature Tabs */}
        <div className="relative mb-8 sm:mb-12">
          <div className="flex justify-start sm:justify-center overflow-x-auto pb-4 sm:pb-0 hide-scrollbar">
            <div className="flex space-x-2 px-2">
              {features.map((feature) => (
                <button
                  key={feature.id}
                  onClick={() => setActiveTab(feature.id)}
                  className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg transition-all duration-300 whitespace-nowrap text-xs sm:text-sm
                    backdrop-blur-md
                    ${activeTab === feature.id 
                      ? `bg-accent-${feature.color}/10 sm:bg-accent-${feature.color}/20 text-accent-${feature.color} border border-accent-${feature.color}/20 sm:border-accent-${feature.color}/30` 
                      : 'text-gray-400 hover:text-white hover:bg-white/5'}`}
                >
                  {feature.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Feature Description */}
        <div className="hidden sm:block">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center mb-8 max-w-2xl mx-auto px-4 backdrop-blur-sm bg-dark-bg/30 rounded-2xl p-6"
            >
              <p className="text-sm sm:text-base text-gray-300">
                {activeTab === 'tracking' && 
                  "Track feedings, sleep, and diaper changes with our intuitive timer system. Get real-time updates and easily log your baby's daily activities."
                }
                {activeTab === 'wellness' && 
                  "Monitor your mood, sleep quality, and energy levels with beautiful daily check-ins. Your well-being matters just as much as your baby's."
                }
                {activeTab === 'learning' && 
                  "Your questions matter, big or small. Get thoughtful answers from our AI assistant, learn from expert videos, and explore helpful articles - all designed to support you through every step of your parenting journey."
                }
                {activeTab === 'health' && 
                  "Keep track of important health events, vaccinations, and check-ups. Never miss a crucial health milestone with our organized tracking system."
                }
                {activeTab === 'stats' && 
                  "Get a complete overview of your baby's daily activities, upcoming appointments, and quick access to essential tracking features - all in one beautiful dashboard."
                }
              </p>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Preview Window */}
        <div className="relative">
          <div className="relative flex justify-center">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="w-full max-w-[320px]"
              >
                <PhoneMockup>
                  {activeTab === 'tracking' && <TrackingFormPreview />}
                  {activeTab === 'wellness' && <WellnessPreview />}
                  {activeTab === 'learning' && <LearningPreview />}
                  {activeTab === 'health' && <HealthFormPreview />}
                  {activeTab === 'stats' && <StatsPreview />}
                </PhoneMockup>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureShowcase; 