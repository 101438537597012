import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import BabyTracking from './components/BabyTracking';
import Wellness from './components/Wellness';
import HealthTracking from './components/HealthTracking';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import PrivateRoute from './components/auth/PrivateRoute';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import UserProfile from './components/profile/UserProfile';
import AdminRoute from './components/admin/AdminRoute';
import { AdminLayout } from './components/admin/AdminLayout';
import { AdminDashboard } from './components/admin/pages/AdminDashboard';
import { VideoManagement } from './components/admin/pages/VideoManagement';
import { ArticleManagement } from './components/admin/pages/ArticleManagement';
import { LandingPage } from './components/LandingPage';
import Victories from './components/victories/Victories';
import { TimerProvider } from './contexts/TimerContext';
import Learning from './components/learning/Learning';
import BabyHealth from './components/health/BabyHealth';
import { HealthSchedule } from './components/health/HealthSchedule';

// Create a new component to handle the landing page logic
function LandingPageRoute() {
  const { currentUser, loading } = useAuth();

  // Wait for auth state to be determined
  if (loading) return null;

  // Redirect to dashboard if user is logged in
  if (currentUser) {
    return <Navigate to="/app" replace />;
  }

  // Show landing page for non-authenticated users
  return <LandingPage />;
}

function App() {
  return (
    <AuthProvider>
      <TimerProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPageRoute />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/app" element={<PrivateRoute><Layout /></PrivateRoute>}>
              <Route index element={<Dashboard />} />
              <Route path="baby" element={<BabyTracking />} />
              <Route path="wellness" element={<Wellness />} />
              <Route path="health" element={<HealthTracking />} />
              <Route path="victories" element={<Victories />} />
              <Route path="learning" element={<Learning />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="baby-health" element={<BabyHealth />} />
              <Route path="health-schedule" element={<HealthSchedule />} />
            </Route>
            <Route path="/admin" element={<AdminRoute><AdminLayout /></AdminRoute>}>
              <Route index element={<AdminDashboard />} />
              <Route path="videos" element={<VideoManagement />} />
              <Route path="articles" element={<ArticleManagement />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </TimerProvider>
    </AuthProvider>
  );
}

export default App;
