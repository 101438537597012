import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import { XMarkIcon, ExclamationTriangleIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';
import { HealthConcern } from '../../types/healthConcerns';
import { getAISuggestion } from '../../services/aiService';

interface HealthConcernFormProps {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  initialData?: HealthConcern;
}

export function HealthConcernForm({ isOpen, onClose, initialData }: HealthConcernFormProps) {
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    severity: initialData?.severity || 'low',
    status: initialData?.status || 'active',
    date: initialData?.date || Date.now()
  });
  const [aiSuggestion, setAiSuggestion] = useState<string | null>(null);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [showAIAdvice, setShowAIAdvice] = useState(false);

  const getAIAdvice = async () => {
    if (!formData.title) {
      return;
    }
    
    setIsLoadingAI(true);
    setShowAIAdvice(true);
    try {
      const suggestion = await getAISuggestion({
        title: formData.title,
        description: formData.description,
        severity: formData.severity
      });
      setAiSuggestion(suggestion);
    } catch (error) {
      console.error('Error getting AI suggestion:', error);
    } finally {
      setIsLoadingAI(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (initialData) {
        await storage.updateHealthConcern({
          ...initialData,
          ...formData,
          date: Date.now()
        });
      } else {
        await storage.addHealthConcern({
          ...formData,
          date: Date.now()
        });
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving health concern:', error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-lg w-full rounded-xl bg-dark-card border border-gray-800 p-4 sm:p-6 max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between mb-4 sticky top-0 bg-dark-card z-10 pb-2">
            <Dialog.Title className="text-lg font-medium text-white">
              {initialData ? 'Edit Health Concern' : 'New Health Concern'}
            </Dialog.Title>
            <button
              onClick={() => onClose()}
              className="p-1 rounded-lg hover:bg-gray-800/50 transition-colors"
            >
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Title</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  placeholder="e.g., Persistent Cough"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Description (Optional)
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white h-24"
                  placeholder="Describe the symptoms or concern..."
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Severity</label>
                  <select
                    value={formData.severity}
                    onChange={(e) => setFormData(prev => ({ ...prev, severity: e.target.value as any }))}
                    className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Status</label>
                  <select
                    value={formData.status}
                    onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value as any }))}
                    className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  >
                    <option value="active">Active</option>
                    <option value="resolved">Resolved</option>
                  </select>
                </div>
              </div>
            </div>

            {formData.title && (
              <button
                type="button"
                onClick={getAIAdvice}
                className="w-full px-4 py-2 rounded-lg bg-accent-mint/20 text-accent-mint 
                         border border-accent-mint/30 hover:bg-accent-mint/30 transition-colors
                         flex items-center justify-center space-x-2"
              >
                {isLoadingAI ? (
                  <>
                    <div className="w-4 h-4 border-2 border-accent-mint/30 border-t-accent-mint rounded-full animate-spin" />
                    <span>AI is thinking...</span>
                  </>
                ) : (
                  <>
                    <SparklesIcon className="h-5 w-5" />
                    <span>What does AI think?</span>
                  </>
                )}
              </button>
            )}

            {showAIAdvice && (
              <div className="space-y-3">
                <div className="bg-accent-rose/10 border border-accent-rose/20 rounded-lg p-3">
                  <div className="flex items-start space-x-2">
                    <ExclamationTriangleIcon className="h-5 w-5 text-accent-rose flex-shrink-0 mt-0.5" />
                    <p className="text-xs text-gray-300">
                      This AI suggestion is not a substitute for professional medical advice. 
                      Always consult with healthcare providers for medical decisions.
                    </p>
                  </div>
                </div>

                {aiSuggestion && (
                  <div className="bg-dark-bg rounded-lg p-4 border border-gray-800">
                    <div className="prose prose-invert max-w-none">
                      <div className="text-sm text-gray-300 whitespace-pre-line">
                        {aiSuggestion}
                      </div>
                      {formData.severity === 'high' && (
                        <div className="text-sm text-accent-rose mt-4 flex items-center space-x-2">
                          <ExclamationTriangleIcon className="h-5 w-5 flex-shrink-0" />
                          <span>Given the severity, it's recommended to consult with your healthcare provider.</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="flex justify-end space-x-3 pt-4 sticky bottom-0 bg-dark-card border-t border-gray-800 mt-6 -mx-4 px-4 py-3 sm:static sm:border-0 sm:bg-transparent sm:px-0 sm:py-0">
              <button
                type="button"
                onClick={() => onClose()}
                className="px-4 py-2 rounded-lg border border-gray-800 text-gray-300 hover:bg-gray-800/50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded-lg bg-accent-rose/20 text-accent-rose border border-accent-rose/30 hover:bg-accent-rose/30"
              >
                {initialData ? 'Update' : 'Save'}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default HealthConcernForm; 