import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  PlusIcon, 
  CalendarIcon,
  CheckCircleIcon,
  ClockIcon,
  MapPinIcon,
  UserIcon,
  PencilIcon,
  TrashIcon,
  BeakerIcon
} from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';
import { Toast } from '../common/Toast';
import { Appointment, Vaccination } from '../../types/appointments';
import { AppointmentForm } from './AppointmentForm';
import { VaccinationForm } from './VaccinationForm';
import { format } from 'date-fns';
import { ConfirmDialog } from '../common/ConfirmDialog';
import { useAuth } from '../../contexts/AuthContext';
import { User as FirebaseUser } from 'firebase/auth';
import { VaccinationProgress } from './VaccinationProgress';
import { addToCalendar } from '../../utils/calendarUtils';

type TabType = 'appointments' | 'vaccinations';

export function HealthSchedule() {
  const [activeTab, setActiveTab] = useState<TabType>('appointments');
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false);
  const [isVaccinationFormOpen, setIsVaccinationFormOpen] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [selectedItem, setSelectedItem] = useState<Appointment | Vaccination | undefined>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{ id: string; type: TabType } | null>(null);
  const [showVaccinationDeleteConfirm, setShowVaccinationDeleteConfirm] = useState(false);
  const [vaccinationToDelete, setVaccinationToDelete] = useState<Vaccination | null>(null);
  const { currentUser } = useAuth() as { currentUser: FirebaseUser | null };

  useEffect(() => {
    loadData();
  }, [currentUser]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const [appointmentsData, vaccinationsData] = await Promise.all([
        storage.getAppointments(),
        storage.getVaccinations()
      ]);
      setAppointments(appointmentsData.sort((a, b) => a.date - b.date));
      setVaccinations(vaccinationsData.sort((a, b) => (a.date || 0) - (b.date || 0)));
    } catch (error) {
      console.error('Error loading health schedule:', error);
      setToast({ message: 'Failed to load schedule', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!itemToDelete) return;

    try {
      if (itemToDelete.type === 'appointments') {
        await storage.deleteAppointment(itemToDelete.id);
      } else {
        await storage.deleteVaccination(itemToDelete.id);
      }
      await loadData();
      setToast({ message: `${itemToDelete.type === 'appointments' ? 'Appointment' : 'Vaccination'} deleted successfully`, type: 'success' });
    } catch (error) {
      console.error('Error deleting item:', error);
      setToast({ message: 'Failed to delete item', type: 'error' });
    } finally {
      setShowDeleteConfirm(false);
      setItemToDelete(null);
    }
  };

  const handleDeleteVaccination = async (vaccination: Vaccination) => {
    setVaccinationToDelete(vaccination);
    setShowVaccinationDeleteConfirm(true);
  };

  const handleEditVaccination = (vaccination: Vaccination) => {
    setSelectedItem(vaccination);
    setIsVaccinationFormOpen(true);
  };

  const handleToggleVaccinationStatus = async (vaccination: Vaccination) => {
    try {
      const newStatus = vaccination.status === 'completed' ? 'scheduled' : 'completed';
      await storage.updateVaccination({
        ...vaccination,
        status: newStatus,
        date: newStatus === 'completed' ? Date.now() : vaccination.date,
        doses: vaccination.doses.map(dose => ({
          ...dose,
          completed: newStatus === 'completed'
        }))
      });
      await loadData();
      setToast({ 
        message: `Vaccination marked as ${newStatus}`, 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error updating vaccination status:', error);
      setToast({ message: 'Failed to update status', type: 'error' });
    }
  };

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-4 sm:p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-lavender">Health Schedule</h2>
        <p className="text-gray-400 mt-2">
          Track appointments and vaccinations
        </p>
      </div>

      {/* Tabs and Add Button */}
      <div className="flex flex-col sm:flex-row sm:items-center space-y-3 sm:space-y-0 sm:space-x-3">
        <div className="flex space-x-2 overflow-x-auto hide-scrollbar">
          <button
            onClick={() => setActiveTab('appointments')}
            className={`px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 flex-shrink-0
              ${activeTab === 'appointments'
                ? 'bg-accent-lavender/20 text-accent-lavender border border-accent-lavender/30'
                : 'text-gray-400 hover:text-white hover:bg-gray-800/50'}`}
          >
            <CalendarIcon className="h-5 w-5" />
            <span>Appointments</span>
          </button>
          <button
            onClick={() => setActiveTab('vaccinations')}
            className={`px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 flex-shrink-0
              ${activeTab === 'vaccinations'
                ? 'bg-accent-lavender/20 text-accent-lavender border border-accent-lavender/30'
                : 'text-gray-400 hover:text-white hover:bg-gray-800/50'}`}
          >
            <BeakerIcon className="h-5 w-5" />
            <span>Vaccinations</span>
          </button>
        </div>

        <button
          onClick={() => {
            setSelectedItem(undefined);
            activeTab === 'appointments' 
              ? setIsAppointmentFormOpen(true)
              : setIsVaccinationFormOpen(true);
          }}
          className="w-full sm:w-auto bg-accent-lavender/20 text-accent-lavender px-4 py-2 rounded-lg 
                   border border-accent-lavender/30 flex items-center justify-center space-x-2 
                   hover:bg-accent-lavender/30 transition-colors"
        >
          <PlusIcon className="h-5 w-5" />
          <span>Add {activeTab === 'appointments' ? 'Appointment' : 'Vaccination'}</span>
        </button>
      </div>

      {/* Content */}
      {isLoading ? (
        <div className="flex items-center justify-center h-40">
          <div className="text-accent-lavender animate-pulse">Loading schedule...</div>
        </div>
      ) : activeTab === 'appointments' ? (
        <div className="space-y-3">
          {appointments.length === 0 ? (
            <div className="text-center py-8 text-gray-400">
              No appointments scheduled yet.
            </div>
          ) : (
            appointments.map(appointment => (
              <motion.div
                key={appointment.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`bg-dark-card rounded-lg p-4 border ${
                  appointment.completed 
                    ? 'border-gray-800 opacity-75' 
                    : 'border-accent-lavender/30'
                }`}
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-medium text-white">{appointment.title}</h3>
                    <div className="flex items-center space-x-4 mt-2 text-sm text-gray-400">
                      <div className="flex items-center space-x-1">
                        <ClockIcon className="h-4 w-4" />
                        <span>{format(appointment.date, 'MMM d, yyyy')}</span>
                        {appointment.time && <span>at {appointment.time}</span>}
                      </div>
                      {appointment.location && (
                        <div className="flex items-center space-x-1">
                          <MapPinIcon className="h-4 w-4" />
                          <span>{appointment.location}</span>
                        </div>
                      )}
                      {appointment.provider && (
                        <div className="flex items-center space-x-1">
                          <UserIcon className="h-4 w-4" />
                          <span>{appointment.provider}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {!appointment.completed && (
                      <button
                        onClick={() => {
                          const date = new Date(appointment.date);
                          if (appointment.time) {
                            const [hours, minutes] = appointment.time.split(':');
                            date.setHours(parseInt(hours), parseInt(minutes));
                          }
                          
                          addToCalendar({
                            title: appointment.title,
                            description: appointment.notes,
                            startDate: date,
                            location: appointment.location,
                          });
                        }}
                        className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-gold"
                        title="Add to calendar"
                      >
                        <CalendarIcon className="h-5 w-5" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setSelectedItem(appointment);
                        setIsAppointmentFormOpen(true);
                      }}
                      className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-lavender"
                      title="Edit appointment"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => {
                        setItemToDelete({ id: appointment.id, type: 'appointments' });
                        setShowDeleteConfirm(true);
                      }}
                      className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-rose"
                      title="Delete appointment"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </motion.div>
            ))
          )}
        </div>
      ) : (
        <div className="space-y-4">
          <VaccinationProgress 
            vaccinations={vaccinations}
            onEdit={handleEditVaccination}
            onDelete={handleDeleteVaccination}
            onToggleStatus={handleToggleVaccinationStatus}
          />
          
          {/* Add New Vaccination Button */}
          <button
            onClick={() => {
              setSelectedItem(undefined);
              setIsVaccinationFormOpen(true);
            }}
            className="w-full py-3 rounded-lg bg-accent-lavender/20 text-accent-lavender 
                     border border-accent-lavender/30 hover:bg-accent-lavender/30 
                     transition-colors flex items-center justify-center space-x-2"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add New Vaccination</span>
          </button>

          {/* Vaccination Delete Confirmation */}
          <ConfirmDialog
            isOpen={showVaccinationDeleteConfirm}
            onClose={() => {
              setShowVaccinationDeleteConfirm(false);
              setVaccinationToDelete(null);
            }}
            onConfirm={async () => {
              if (!vaccinationToDelete) return;
              try {
                await storage.deleteVaccination(vaccinationToDelete.id);
                await loadData();
                setToast({ message: 'Vaccination deleted successfully', type: 'success' });
              } catch (error) {
                console.error('Error deleting vaccination:', error);
                setToast({ message: 'Failed to delete vaccination', type: 'error' });
              }
              setShowVaccinationDeleteConfirm(false);
              setVaccinationToDelete(null);
            }}
            title="Delete Vaccination"
            message="Are you sure you want to delete this vaccination? This action cannot be undone."
          />
        </div>
      )}

      {/* Forms */}
      <AppointmentForm 
        isOpen={isAppointmentFormOpen}
        onClose={(success) => {
          setIsAppointmentFormOpen(false);
          setSelectedItem(undefined);
          if (success) {
            loadData();
            setToast({ message: 'Appointment saved!', type: 'success' });
          }
        }}
        initialData={selectedItem as Appointment}
      />

      <VaccinationForm 
        isOpen={isVaccinationFormOpen}
        onClose={(success) => {
          setIsVaccinationFormOpen(false);
          setSelectedItem(undefined);
          if (success) {
            loadData();
            setToast({ message: 'Vaccination saved!', type: 'success' });
          }
        }}
        initialData={selectedItem as Vaccination}
      />

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false);
          setItemToDelete(null);
        }}
        onConfirm={handleDelete}
        title={`Delete ${itemToDelete?.type === 'appointments' ? 'Appointment' : 'Vaccination'}`}
        message={`Are you sure you want to delete this ${
          itemToDelete?.type === 'appointments' ? 'appointment' : 'vaccination'
        }? This action cannot be undone.`}
      />

      {/* Toast */}
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default HealthSchedule; 