import React from 'react';
import { TrackingEntry } from '../../types/tracking';
import type { TooltipProps } from 'recharts';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface TrendAnalysisProps {
  entries: TrackingEntry[];
  type: 'feeding' | 'sleep' | 'diaper';
  timeRange: string;
}

interface ChartData {
  date: string;
  count: number;
  avgDuration: number;
}

interface PayloadItem {
  name: string;
  value: number;
  color: string;
}

export function TrendAnalysis({ entries, type, timeRange }: TrendAnalysisProps) {
  const getChartData = (): ChartData[] => {
    const days = timeRange === '7days' ? 7 : timeRange === '14days' ? 14 : timeRange === '30days' ? 30 : 90;
    const data: ChartData[] = [];
    const now = new Date();

    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(date.getDate() - i);
      const dayStr = date.toISOString().split('T')[0];

      const dayEntries = entries.filter(entry => {
        const entryDate = new Date(entry.timestamp);
        return entryDate.toISOString().split('T')[0] === dayStr;
      });

      data.push({
        date: dayStr,
        count: dayEntries.length,
        avgDuration: type !== 'diaper' ? 
          Math.round(dayEntries.reduce((acc, curr) => acc + (curr.duration || 0), 0) / (dayEntries.length || 1)) : 0
      });
    }

    return data;
  };

  const getInsights = () => {
    const data = getChartData();
    const insights: string[] = [];

    // Frequency trends
    const avgCount = data.reduce((acc, curr) => acc + curr.count, 0) / data.length;
    const recentAvg = data.slice(-3).reduce((acc, curr) => acc + curr.count, 0) / 3;
    
    if (recentAvg > avgCount * 1.2) {
      insights.push(`${type.charAt(0).toUpperCase() + type.slice(1)} frequency has increased by ${Math.round((recentAvg - avgCount) / avgCount * 100)}% recently`);
    } else if (recentAvg < avgCount * 0.8) {
      insights.push(`${type.charAt(0).toUpperCase() + type.slice(1)} frequency has decreased by ${Math.round((avgCount - recentAvg) / avgCount * 100)}% recently`);
    }

    // Duration trends (for feeding and sleep)
    if (type !== 'diaper') {
      const avgDuration = data.reduce((acc, curr) => acc + curr.avgDuration, 0) / data.length;
      const recentDuration = data.slice(-3).reduce((acc, curr) => acc + curr.avgDuration, 0) / 3;

      if (Math.abs(recentDuration - avgDuration) > avgDuration * 0.2) {
        insights.push(
          `Average duration has ${recentDuration > avgDuration ? 'increased' : 'decreased'} by ${
            Math.round(Math.abs(recentDuration - avgDuration) / avgDuration * 100)
          }%`
        );
      }
    }

    return insights;
  };

  const formatYAxis = (value: number): string => {
    if (type !== 'diaper' && value > 60) {
      return `${Math.round(value/60)}h`;
    }
    return value.toString();
  };

  const CustomTooltip = ({ 
    active, 
    payload, 
    label 
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-dark-bg p-3 rounded-lg border border-gray-800 shadow-lg">
          <p className="text-sm text-gray-400">
            {new Date(label as string).toLocaleDateString(undefined, { 
              month: 'short', 
              day: 'numeric' 
            })}
          </p>
          {(payload as PayloadItem[]).map((pld: PayloadItem, index: number) => (
            <p key={index} className="text-sm" style={{ color: pld.color }}>
              {pld.name === 'count' ? (
                `${pld.value} times`
              ) : (
                `${Math.round(pld.value/60)}h ${pld.value%60}m`
              )}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const formatXAxisTick = (date: string): string => {
    return new Date(date).toLocaleDateString(undefined, { 
      month: 'short', 
      day: 'numeric' 
    });
  };

  const formatLegend = (value: string): string => {
    if (value === 'count') return 'Frequency';
    if (value === 'avgDuration') return 'Duration';
    return value;
  };

  return (
    <div className="space-y-4">
      <div className="h-[300px] sm:h-64 md:h-48"> {/* Taller on mobile */}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart 
            data={getChartData()} 
            margin={{ top: 5, right: 10, bottom: 20, left: 10 }}
          >
            <XAxis 
              dataKey="date" 
              stroke="#6B7280"
              tick={{ fontSize: 10 }}
              angle={-45}
              textAnchor="end"
              tickFormatter={formatXAxisTick}
              height={50}
              interval="preserveStartEnd"
            />
            <YAxis 
              stroke="#6B7280" 
              tick={{ fontSize: 10 }}
              tickFormatter={formatYAxis}
              width={30}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              verticalAlign="top" 
              height={36}
              formatter={formatLegend}
              wrapperStyle={{ fontSize: '12px' }}
            />
            <Line 
              name="count"
              type="monotone" 
              dataKey="count" 
              stroke="#8B5CF6" 
              strokeWidth={2}
              dot={{ r: 2 }}
              activeDot={{ r: 4 }}
            />
            {type !== 'diaper' && (
              <Line 
                name="avgDuration"
                type="monotone" 
                dataKey="avgDuration" 
                stroke="#10B981" 
                strokeWidth={2}
                dot={{ r: 2 }}
                activeDot={{ r: 4 }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="space-y-2 p-3 bg-dark-bg rounded-lg border border-gray-800">
        <h4 className="text-sm font-medium text-accent-mint">Key Insights</h4>
        <ul className="space-y-2">
          {getInsights().map((insight, index) => (
            <li key={index} className="text-sm text-gray-400 flex items-start space-x-2">
              <span className="w-1.5 h-1.5 rounded-full bg-accent-mint/50 mt-1.5 flex-shrink-0" />
              <span>{insight}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
} 