import React, { useState, useEffect } from 'react';
import { storage } from '../../../services/storage';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { motion } from 'framer-motion';
import { UserActivityStats, AdminStats } from '../../../types/admin';

type SortField = 'lastActive' | 'totalEntries' | 'avgEntries' | 'email';
type SortOrder = 'asc' | 'desc';

export function AdminDashboard() {
  const [stats, setStats] = useState<AdminStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserActivityStats | null>(null);
  const [sortField, setSortField] = useState<SortField>('lastActive');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const stats = await storage.getAdminStats();
      setStats(stats);
    } catch (error) {
      console.error('Error loading admin stats:', error);
      setError('Failed to load stats');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleString();
  };

  const getSortedUsers = (users: UserActivityStats[]) => {
    return [...users].sort((a, b) => {
      let comparison = 0;
      
      switch (sortField) {
        case 'lastActive':
          comparison = b.lastActive - a.lastActive;
          break;
        case 'totalEntries':
          comparison = b.totalEntries - a.totalEntries;
          break;
        case 'avgEntries':
          comparison = b.averageEntriesPerDay - a.averageEntriesPerDay;
          break;
        case 'email':
          comparison = a.email.localeCompare(b.email);
          break;
      }

      return sortOrder === 'desc' ? comparison : -comparison;
    });
  };

  const toggleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(current => current === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
  };

  const SortIndicator = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <span className="text-gray-600">↕</span>;
    return <span>{sortOrder === 'desc' ? '↓' : '↑'}</span>;
  };

  if (isLoading) return <div className="text-center py-8">Loading stats...</div>;
  if (error) return <div className="text-center py-8 text-accent-rose">{error}</div>;
  if (!stats) return <div className="text-center py-8">No stats available</div>;

  return (
    <div className="space-y-6">
      {/* Overall Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-dark-card p-4 rounded-lg border border-gray-800"
        >
          <h3 className="text-sm text-gray-400">Total Users</h3>
          <p className="text-2xl font-bold text-white mt-1">{stats.totalUsers}</p>
          <p className="text-xs text-accent-mint mt-1">
            {stats.activeUsers} active in last 7 days
          </p>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-dark-card p-4 rounded-lg border border-gray-800"
        >
          <h3 className="text-sm text-gray-400">Total Entries</h3>
          <p className="text-2xl font-bold text-white mt-1">
            {Object.values(stats.totalEntries).reduce((a, b) => a + b, 0)}
          </p>
          <div className="flex justify-between text-xs mt-1">
            <span className="text-accent-peach">Feeding: {stats.totalEntries.feeding}</span>
            <span className="text-accent-mint">Sleep: {stats.totalEntries.sleep}</span>
            <span className="text-accent-lavender">Diaper: {stats.totalEntries.diaper}</span>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-dark-card p-4 rounded-lg border border-gray-800"
        >
          <h3 className="text-sm text-gray-400">Daily Averages</h3>
          <p className="text-2xl font-bold text-white mt-1">
            {Object.values(stats.dailyAverages).reduce((a, b) => a + b, 0).toFixed(1)}
          </p>
          <div className="flex justify-between text-xs mt-1">
            <span className="text-accent-peach">Feeding: {stats.dailyAverages.feeding}</span>
            <span className="text-accent-mint">Sleep: {stats.dailyAverages.sleep}</span>
            <span className="text-accent-lavender">Diaper: {stats.dailyAverages.diaper}</span>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-dark-card p-4 rounded-lg border border-gray-800"
        >
          <h3 className="text-sm text-gray-400">Peak Activity</h3>
          <p className="text-2xl font-bold text-white mt-1">
            {stats.peakActivityHours[0]?.hour}:00
          </p>
          <p className="text-xs text-accent-mint mt-1">
            {stats.peakActivityHours[0]?.count} entries
          </p>
        </motion.div>
      </div>

      {/* Updated User List with sorting */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="bg-dark-card p-4 rounded-lg border border-gray-800"
      >
        <h3 className="text-lg font-medium text-white mb-4">User Activity</h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left text-xs text-gray-400">
                <th 
                  className="p-2 cursor-pointer hover:text-white"
                  onClick={() => toggleSort('email')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Email</span>
                    <SortIndicator field="email" />
                  </div>
                </th>
                <th className="p-2">Baby Name</th>
                <th 
                  className="p-2 cursor-pointer hover:text-white"
                  onClick={() => toggleSort('lastActive')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Last Active</span>
                    <SortIndicator field="lastActive" />
                  </div>
                </th>
                <th 
                  className="p-2 cursor-pointer hover:text-white"
                  onClick={() => toggleSort('totalEntries')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Total Entries</span>
                    <SortIndicator field="totalEntries" />
                  </div>
                </th>
                <th 
                  className="p-2 cursor-pointer hover:text-white"
                  onClick={() => toggleSort('avgEntries')}
                >
                  <div className="flex items-center space-x-1">
                    <span>Daily Avg</span>
                    <SortIndicator field="avgEntries" />
                  </div>
                </th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {stats && getSortedUsers(stats.userActivity).map((user) => (
                <tr 
                  key={user.userId} 
                  className="border-t border-gray-800 hover:bg-gray-800/30 transition-colors"
                >
                  <td className="p-2 text-white">{user.email}</td>
                  <td className="p-2 text-gray-400">{user.babyName || '-'}</td>
                  <td className="p-2 text-gray-400">{formatDate(user.lastActive)}</td>
                  <td className="p-2 text-accent-mint">{user.totalEntries}</td>
                  <td className="p-2 text-accent-lavender">{user.averageEntriesPerDay}</td>
                  <td className="p-2">
                    <button
                      onClick={() => setSelectedUser(user)}
                      className="text-xs px-2 py-1 bg-accent-mint/20 text-accent-mint rounded hover:bg-accent-mint/30"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </motion.div>

      {/* User Detail Modal */}
      {selectedUser && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-dark-bg p-6 rounded-lg w-full max-w-2xl mx-4">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-medium text-white">{selectedUser.email}</h3>
                <p className="text-sm text-gray-400">Baby: {selectedUser.babyName || 'Not set'}</p>
              </div>
              <button 
                onClick={() => setSelectedUser(null)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            <div className="grid grid-cols-3 gap-4 mb-6">
              <div className="bg-dark-card p-3 rounded-lg">
                <div className="text-sm text-gray-400">Feeding</div>
                <div className="text-lg font-medium text-accent-peach">
                  {selectedUser.entryTypes.feeding}
                </div>
              </div>
              <div className="bg-dark-card p-3 rounded-lg">
                <div className="text-sm text-gray-400">Sleep</div>
                <div className="text-lg font-medium text-accent-mint">
                  {selectedUser.entryTypes.sleep}
                </div>
              </div>
              <div className="bg-dark-card p-3 rounded-lg">
                <div className="text-sm text-gray-400">Diaper</div>
                <div className="text-lg font-medium text-accent-lavender">
                  {selectedUser.entryTypes.diaper}
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-400 mb-2">Recent Activity</h4>
                <div className="h-48">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={selectedUser.recentActivity}>
                      <XAxis 
                        dataKey="date" 
                        stroke="#6B7280"
                        tick={{ fontSize: 10 }}
                      />
                      <YAxis 
                        stroke="#6B7280"
                        tick={{ fontSize: 10 }}
                      />
                      <Tooltip 
                        contentStyle={{ 
                          backgroundColor: '#1F2937',
                          border: '1px solid #374151'
                        }}
                      />
                      <Line 
                        type="monotone" 
                        dataKey="count" 
                        stroke="#8B5CF6" 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="text-sm text-gray-400">
                <p>Average Entries/Day: {selectedUser.averageEntriesPerDay}</p>
                <p>Last Active: {formatDate(selectedUser.lastActive)}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const COLORS = ['#8B5CF6', '#10B981', '#F59E0B']; 