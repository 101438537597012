import React, { useState, useEffect } from 'react';
import { TrackingEntry } from '../../types/tracking';
import { aiService } from '../../services/aiService';
import { motion } from 'framer-motion';
import { SparklesIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

interface Props {
  entries: TrackingEntry[];
  type: 'feeding' | 'sleep' | 'diaper';
}

export function TimePatternAnalysis({ entries, type }: Props) {
  const [insights, setInsights] = useState<string[]>([]);
  const [encouragement, setEncouragement] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const parseAIResponse = (response: string) => {
    const bulletPoints = response
      .split('\n')
      .filter(line => line.trim().startsWith('•') || line.trim().startsWith('-'))
      .map(line => line.replace(/^[•-]\s*/, '').trim());

    // Genuine & Heartfelt
    const encouragements = [
      "Every little moment with your baby is precious 💝",
      "Your gentle touch makes all the difference 💫",
      "Your baby feels so safe in your arms ✨",
      "The way you care is beautiful to see 🌟",
      "Your love shines through in everything you do 💖",
      "Such a beautiful bond you're building 💝",
      "Your baby's eyes light up with you ⭐️",
      "The love between you two is magical ✨",

      // Supportive & Understanding
      "Those quiet moments together mean everything 💫",
      "Your patience is a gift to your little one 💝",
      "Every cuddle tells your baby 'I'm here' 🤗",
      "Your gentle ways speak volumes of love ✨",
      "The comfort you give is everything 💖",
      "Your presence is your baby's safe place 🌟",
      "Such tender care you give 💫",
      "Your love story grows more beautiful each day ✨",

      // Warm & Nurturing
      "The way you nurture is so beautiful 💝",
      "Your baby flourishes in your love ✨",
      "Such gentle strength you show 💫",
      "Your love creates the sweetest memories 🌟",
      "Every touch shares your love 💖",
      "Your baby feels so treasured with you ⭐️",
      "The connection you share is precious 💝",
      "Your love makes everything brighter ✨",

      // Sweet & Tender
      "Your love is your baby's comfort 💫",
      "Such beautiful moments you create 💝",
      "Your gentle ways mean everything ✨",
      "The peace your baby finds with you 🌟",
      "Your love speaks in the softest ways 💖",
      "Every moment together is a treasure ⭐️",
      "Your baby's heart rests in your love 💫",
      "The way you love is simply beautiful ✨"
    ];

    return {
      insights: bulletPoints,
      encouragement: encouragements[Math.floor(Math.random() * encouragements.length)]
    };
  };

  const getFunAndMeaningfulInsights = (entries: TrackingEntry[], type: string) => {
    const insights = {
      feeding: {
        fun: [
          "Look at you, mastering the one-handed snack grab! 🦸‍♀️",
          "Feeding champion in action! 💫",
          "Multitasking master at work! ⭐️",
          "Snack breaks and baby feeds - you're nailing it! 🌟",
          "Expert level bottle juggling! 💫"
        ],
        meaningful: [
          "These feeding moments are more than nourishment - they're building trust and connection that will last a lifetime 💝",
          "Each feeding is a chance to bond, to share quiet moments that strengthen your beautiful connection 💫",
          "The patience and love you show during these feeds creates a foundation of security for your little one ✨",
          "In these quiet feeding moments, you're teaching your baby what unconditional love feels like 🌟",
          "The gentle way you respond to your baby's needs shows them they can always count on you 💖"
        ]
      },
      sleep: {
        fun: [
          "That stealth exit was pure magic! 🤫",
          "Ninja-level tip-toe skills! 🥷",
          "Sleep whisperer in action! 💫",
          "Making bedtime beautiful! ✨",
          "Expert level sneaking skills! 🌟"
        ],
        meaningful: [
          "The comfort you create at bedtime helps your baby feel secure enough to rest peacefully 💫",
          "Your consistent, loving presence during sleep times builds trust that carries into sweet dreams 💝",
          "Each gentle bedtime moment shows your baby they're safe and loved as they drift off to sleep ✨",
          "The peace you bring to bedtime routines gives your baby confidence to rest and grow 🌟",
          "Your calming presence during sleep times is building lifelong feelings of security 💖"
        ]
      },
      diaper: {
        fun: [
          "Lightning-fast changes? No problem! ⚡️",
          "Diaper duty dynamo! 💫",
          "Quick-change artist at work! 🎨",
          "Speedy diaper specialist! 🚀",
          "Making changes look easy! ✨"
        ],
        meaningful: [
          "Even these simple moments of care show your baby they can trust you to meet their every need 💝",
          "Your gentle touch during changes tells your baby they're safe and cared for completely 💫",
          "Each tender moment of care, even diaper changes, strengthens your precious bond ✨",
          "The way you make your baby comfortable shows them they're cherished in every moment 🌟",
          "Your consistent, loving care during changes helps your baby feel secure and loved 💖"
        ]
      }
    };

    const typeInsights = insights[type as keyof typeof insights];
    // Randomly choose between fun and meaningful
    const category = Math.random() < 0.5 ? 'fun' : 'meaningful';
    const selectedArray = typeInsights[category];
    return selectedArray[Math.floor(Math.random() * selectedArray.length)];
  };

  const getAIInsights = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const lastWeekEntries = entries
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, 50);

      // Check if there's enough data for meaningful insights
      if (lastWeekEntries.length < 3) {
        setInsights([
          "Keep tracking your baby's activities - insights will appear once you have more entries.",
          "We recommend logging at least a few days of data to see meaningful patterns.",
          "Each entry helps us understand your baby's unique rhythms better."
        ]);
        setEncouragement("You're doing great starting to track your baby's journey! Keep going!");
        return;
      }

      // Get fun insight message if available
      const insightMessage = getFunAndMeaningfulInsights(entries, type);
      
      const prompt = `Analyze this baby's ${type} patterns from the last entries and provide exactly 3 clear insights, 
        each starting with a bullet point (•). Then, provide a separate encouraging message for the mom.
        Here are the entries:
        ${lastWeekEntries.map(entry => `
          - ${new Date(entry.timestamp).toLocaleString()}
          ${entry.duration ? `Duration: ${Math.round(entry.duration / 60)} minutes` : ''}
          ${entry.feedingType ? `Type: ${entry.feedingType}` : ''}
          ${entry.sleepType ? `Type: ${entry.sleepType}` : ''}
          ${entry.diaperType ? `Type: ${entry.diaperType}` : ''}
        `).join('\n')}`;

      const response = await aiService.getInsights(prompt);
      const { insights, encouragement } = parseAIResponse(response);

      setInsights(insights);
      // Combine AI encouragement with insight message if available
      setEncouragement(insightMessage ? 
        `${insightMessage}\n\n${encouragement}` : 
        encouragement
      );
    } catch (error) {
      console.error('Error getting AI insights:', error);
      setError('Unable to generate insights at the moment');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAIInsights();
  }, [entries, type]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <SparklesIcon className="h-5 w-5 text-accent-lavender" />
          <div>
            <h3 className="text-sm font-medium text-white">AI-Powered Insights</h3>
            <p className="text-[10px] text-gray-500">Based on your recent tracking data & pediatric guidelines</p>
          </div>
        </div>
        {entries.length >= 3 && (
          <button
            onClick={getAIInsights}
            disabled={isLoading}
            className="p-2 text-accent-mint hover:bg-accent-mint/10 rounded-lg 
                     transition-colors disabled:opacity-50"
          >
            <ArrowPathIcon className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
          </button>
        )}
      </div>

      <div className="bg-dark-bg rounded-lg p-4 border border-gray-800">
        {entries.length < 3 ? (
          <div className="text-center py-4">
            <div className="flex justify-center mb-3">
              <SparklesIcon className="h-8 w-8 text-accent-lavender/50" />
            </div>
            <p className="text-sm text-gray-400 mb-2">
              Start tracking your baby's {type} to see AI-powered insights
            </p>
            <p className="text-xs text-gray-500">
              We'll analyze patterns and provide helpful observations once you have more entries
            </p>
          </div>
        ) : isLoading ? (
          <div className="flex items-center justify-center py-8 text-accent-lavender">
            Analyzing patterns...
          </div>
        ) : error ? (
          <div className="text-accent-rose text-sm text-center py-4">
            {error}
          </div>
        ) : (
          <div className="space-y-4">
            <div className="space-y-3">
              {insights.map((insight, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-2"
                >
                  <span className="w-1.5 h-1.5 rounded-full bg-accent-lavender/50 mt-2 flex-shrink-0" />
                  <p className="text-sm text-gray-400">{insight}</p>
                </motion.div>
              ))}
            </div>
            
            {encouragement && entries.length >= 3 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="mt-4 p-3 bg-accent-mint/10 rounded-lg border border-accent-mint/20"
              >
                <p className="text-sm text-accent-mint italic">
                  {encouragement}
                </p>
              </motion.div>
            )}
          </div>
        )}
      </div>

      <div className="text-xs text-gray-500 px-2">
        <p>
          ⚠️ AI-generated insights are based on patterns and general knowledge. Always consult with 
          healthcare professionals for medical advice and decisions about your baby's care.
        </p>
      </div>
    </div>
  );
}
