import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { storage } from '../services/storage';
import { 
  HeartIcon, 
  ClockIcon, 
  BeakerIcon,
  CalendarIcon,
  SparklesIcon,
  BookOpenIcon,
  StarIcon
} from '@heroicons/react/24/outline';
import { TrackingEntry } from '../types/tracking';
import { QuickTimer, QuickTimerProps } from './dashboard/QuickTimer';
import { DailySummary, DailySummaryProps } from './dashboard/DailySummary';
import { Toast } from './common/Toast';

function Dashboard() {
  const navigate = useNavigate();
  const [recentFeedings, setRecentFeedings] = useState<TrackingEntry[]>([]);
  const [recentSleep, setRecentSleep] = useState<TrackingEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  const loadContent = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const [feedings, sleep] = await Promise.all([
        storage.getEntries('feeding'),
        storage.getEntries('sleep')
      ]);

      setRecentFeedings(feedings.slice(0, 5));
      setRecentSleep(sleep.slice(0, 5));

    } catch (error) {
      console.error('Error loading dashboard data:', error);
      setError('Failed to load dashboard data. Please try refreshing the page.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-accent-lavender animate-pulse">Loading your dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-accent-rose">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Welcome Section */}
      <div className="bg-gradient-to-br from-accent-rose/10 to-accent-peach/10 rounded-2xl p-4 sm:p-6 border border-accent-rose/20">
        <h1 className="text-xl sm:text-2xl font-bold text-white mb-2">
          Welcome back, Mama! 💝
        </h1>
        <p className="text-sm sm:text-base text-gray-300">
          You're doing amazing. Let's take care of your little one together.
        </p>
      </div>

      {/* Quick Actions Grid - Updated for better mobile layout */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => navigate('/app/baby')}
          className="p-4 sm:p-6 bg-gradient-to-br from-accent-peach/10 to-accent-rose/10 
                   rounded-2xl border border-accent-peach/20 hover:border-accent-peach/30 
                   transition-all group"
        >
          <div className="flex items-center sm:flex-col sm:items-center text-left sm:text-center space-x-3 sm:space-x-0 sm:space-y-3">
            <div className="p-3 bg-accent-peach/20 rounded-xl group-hover:bg-accent-peach/30 transition-colors">
              <HeartIcon className="h-5 w-5 sm:h-6 sm:w-6 text-accent-peach" />
            </div>
            <div>
              <div className="font-medium text-white">Track Baby</div>
              <div className="text-xs sm:text-sm text-gray-400">Feeds, Sleep & More</div>
            </div>
          </div>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => navigate('/app/health-schedule')}
          className="p-4 sm:p-6 bg-gradient-to-br from-accent-mint/10 to-accent-gold/10 
                   rounded-2xl border border-accent-mint/20 hover:border-accent-mint/30 
                   transition-all group"
        >
          <div className="flex items-center sm:flex-col sm:items-center text-left sm:text-center space-x-3 sm:space-x-0 sm:space-y-3">
            <div className="p-3 bg-accent-mint/20 rounded-xl group-hover:bg-accent-mint/30 transition-colors">
              <CalendarIcon className="h-5 w-5 sm:h-6 sm:w-6 text-accent-mint" />
            </div>
            <div>
              <div className="font-medium text-white">Health Schedule</div>
              <div className="text-xs sm:text-sm text-gray-400">Appointments & Vaccines</div>
            </div>
          </div>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => navigate('/app/baby-health')}
          className="p-4 sm:p-6 bg-gradient-to-br from-accent-lavender/10 to-accent-rose/10 
                   rounded-2xl border border-accent-lavender/20 hover:border-accent-lavender/30 
                   transition-all group"
        >
          <div className="flex items-center sm:flex-col sm:items-center text-left sm:text-center space-x-3 sm:space-x-0 sm:space-y-3">
            <div className="p-3 bg-accent-lavender/20 rounded-xl group-hover:bg-accent-lavender/30 transition-colors">
              <BeakerIcon className="h-5 w-5 sm:h-6 sm:w-6 text-accent-lavender" />
            </div>
            <div>
              <div className="font-medium text-white">Health Journal</div>
              <div className="text-xs sm:text-sm text-gray-400">Track Health Concerns</div>
            </div>
          </div>
        </motion.button>
      </div>

      {/* Quick Timer Section */}
      <div className="bg-gradient-to-br from-accent-gold/10 to-accent-mint/10 rounded-2xl p-4 sm:p-6 border border-accent-gold/20">
        <h2 className="text-base sm:text-lg font-medium text-white mb-3 sm:mb-4">Quick Timer</h2>
        <QuickTimer onComplete={() => loadContent()} />
      </div>

      {/* Daily Summary */}
      <div className="bg-gradient-to-br from-accent-mint/10 to-accent-lavender/10 rounded-2xl p-4 sm:p-6 border border-accent-mint/20">
        <h2 className="text-base sm:text-lg font-medium text-white mb-3 sm:mb-4">Today's Overview</h2>
        <DailySummary 
          recentFeedings={recentFeedings}
          recentSleep={recentSleep}
          onViewMore={() => navigate('/app/baby')}
        />
      </div>

      {/* Quick Links */}
      <div className="grid grid-cols-2 gap-3 pb-4">
        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => navigate('/app/learning')}
          className="p-4 sm:p-6 bg-gradient-to-br from-accent-gold/10 to-accent-peach/10 
                   rounded-2xl border border-accent-gold/20 hover:border-accent-gold/30 
                   transition-all group"
        >
          <div className="flex flex-col items-center text-center space-y-2 sm:space-y-3">
            <div className="p-2 sm:p-3 bg-accent-gold/20 rounded-xl group-hover:bg-accent-gold/30 transition-colors">
              <BookOpenIcon className="h-5 w-5 sm:h-6 sm:w-6 text-accent-gold" />
            </div>
            <div>
              <div className="font-medium text-white">Ask AI</div>
              <div className="text-xs sm:text-sm text-gray-400">Get Quick Answers</div>
            </div>
          </div>
        </motion.button>

        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => navigate('/app/wellness')}
          className="p-4 sm:p-6 bg-gradient-to-br from-accent-lavender/10 to-accent-mint/10 
                   rounded-2xl border border-accent-lavender/20 hover:border-accent-lavender/30 
                   transition-all group"
        >
          <div className="flex flex-col items-center text-center space-y-2 sm:space-y-3">
            <div className="p-2 sm:p-3 bg-accent-lavender/20 rounded-xl group-hover:bg-accent-lavender/30 transition-colors">
              <SparklesIcon className="h-5 w-5 sm:h-6 sm:w-6 text-accent-lavender" />
            </div>
            <div>
              <div className="font-medium text-white">Wellness Check</div>
              <div className="text-xs sm:text-sm text-gray-400">Take Care of You</div>
            </div>
          </div>
        </motion.button>
      </div>

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default Dashboard;
