import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, InformationCircleIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';
import { Vaccination } from '../../types/appointments';
import { getAllCountries, getCountrySchedule } from '../../types/vaccinationSchedules';
import { Toast } from '../common/Toast';
import { VaccineSelector } from './VaccineSelector';
import { addToCalendar } from '../../utils/calendarUtils';
import { notificationService } from '../../services/notificationService';
import { useAuth } from '../../contexts/AuthContext';
import { User } from 'firebase/auth';

interface VaccinationFormProps {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  initialData?: Vaccination;
}

type EntryMode = 'manual' | 'schedule';

export function VaccinationForm({ isOpen, onClose, initialData }: VaccinationFormProps) {
  const { currentUser } = useAuth() as { currentUser: User | null };
  const [entryMode, setEntryMode] = useState<EntryMode>('manual');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  
  // Manual entry form state
  const [formData, setFormData] = useState({
    vaccineId: initialData?.vaccineId || '',
    name: initialData?.name || '',
    date: initialData?.date ? new Date(initialData.date).toISOString().split('T')[0] : '',
    status: initialData?.status || 'scheduled',
    dueDate: initialData?.dueDate ? new Date(initialData.dueDate).toISOString().split('T')[0] : '',
    provider: initialData?.provider || '',
    location: initialData?.location || '',
    notes: initialData?.doses?.[0]?.notes || '',
    doseNumber: initialData?.doses?.[0]?.doseNumber || 1
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        vaccineId: initialData.vaccineId,
        name: initialData.name,
        date: new Date(initialData.date).toISOString().split('T')[0],
        status: initialData.status,
        dueDate: initialData.dueDate ? new Date(initialData.dueDate).toISOString().split('T')[0] : '',
        provider: initialData.provider || '',
        location: initialData.location || '',
        notes: initialData.doses?.[0]?.notes || '',
        doseNumber: initialData.doses?.[0]?.doseNumber || 1
      });
    }
  }, [initialData]);

  const handleManualSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    try {
      const submissionData = {
        vaccineId: formData.vaccineId || formData.name.toLowerCase().replace(/\s+/g, '-'),
        name: formData.name,
        date: formData.date ? new Date(formData.date).getTime() : Date.now(),
        status: formData.status as 'scheduled' | 'completed',
        dueDate: formData.status === 'scheduled' 
          ? (formData.dueDate ? new Date(formData.dueDate).getTime() : new Date(formData.date).getTime())
          : undefined,
        doses: [{
          doseNumber: formData.doseNumber,
          date: formData.date ? new Date(formData.date).getTime() : Date.now(),
          completed: formData.status === 'completed',
          provider: formData.provider,
          location: formData.location,
          notes: formData.notes
        }],
        userId: currentUser.uid
      };

      let savedVaccination;
      if (initialData) {
        await storage.updateVaccination({
          ...initialData,
          ...submissionData,
        });
        savedVaccination = { ...initialData, ...submissionData };
      } else {
        savedVaccination = await storage.addVaccination(submissionData);
      }

      onClose(true);
    } catch (error) {
      console.error('Error saving vaccination:', error);
      setToast({ message: 'Failed to save vaccination', type: 'error' });
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = e.target.value as 'scheduled' | 'completed';
    setFormData(prev => ({ 
      ...prev, 
      status: newStatus
    }));
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()} className="relative z-50">
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-lg w-full rounded-xl bg-dark-card border border-gray-800 p-4 sm:p-6 max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between mb-4 sticky top-0 bg-dark-card z-10 pb-2">
            <Dialog.Title className="text-lg font-medium text-white">
              {initialData ? 'Edit Vaccination' : 'Add Vaccination'}
            </Dialog.Title>
            <button onClick={() => onClose()} className="p-1 rounded-lg hover:bg-gray-800/50">
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </button>
          </div>

          {/* Entry Mode Selection */}
          {!initialData && (
            <div className="mb-6">
              <div className="flex space-x-2">
                <button
                  onClick={() => setEntryMode('manual')}
                  className={`flex-1 py-2 px-4 rounded-lg transition-colors
                    ${entryMode === 'manual' 
                      ? 'bg-accent-mint/20 text-accent-mint border border-accent-mint/30' 
                      : 'text-gray-400 hover:text-white hover:bg-gray-800/50'}`}
                >
                  Manual Entry
                </button>
                <button
                  onClick={() => setEntryMode('schedule')}
                  className={`flex-1 py-2 px-4 rounded-lg transition-colors
                    ${entryMode === 'schedule' 
                      ? 'bg-accent-mint/20 text-accent-mint border border-accent-mint/30' 
                      : 'text-gray-400 hover:text-white hover:bg-gray-800/50'}`}
                >
                  From Schedule
                </button>
              </div>
            </div>
          )}

          {entryMode === 'manual' ? (
            // Manual Entry Form
            <form onSubmit={handleManualSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Vaccine Name
                </label>
                <VaccineSelector
                  value={formData.name}
                  onChange={(value) => setFormData(prev => ({ ...prev, name: value }))}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Status
                </label>
                <select
                  value={formData.status}
                  onChange={handleStatusChange}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                >
                  <option value="scheduled">Scheduled (Upcoming)</option>
                  <option value="completed">Completed (Already Given)</option>
                </select>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    {formData.status === 'completed' ? 'Date Given' : 'Scheduled Date'}
                  </label>
                  <input
                    type="date"
                    value={formData.date}
                    onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                    className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Dose Number
                  </label>
                  <input
                    type="number"
                    min="1"
                    value={formData.doseNumber}
                    onChange={(e) => setFormData(prev => ({ ...prev, doseNumber: parseInt(e.target.value) }))}
                    className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                    required
                  />
                </div>
              </div>

              {formData.status === 'scheduled' && (
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Due Date (Optional)
                  </label>
                  <input
                    type="date"
                    value={formData.dueDate}
                    onChange={(e) => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
                    className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Provider (Optional)
                </label>
                <input
                  type="text"
                  value={formData.provider}
                  onChange={(e) => setFormData(prev => ({ ...prev, provider: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  placeholder="Healthcare provider name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Location (Optional)
                </label>
                <input
                  type="text"
                  value={formData.location}
                  onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  placeholder="Where was/will be the vaccination given"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Notes (Optional)
                </label>
                <textarea
                  value={formData.notes}
                  onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white h-24"
                  placeholder="Any additional notes..."
                />
              </div>

              <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3 pt-4 sticky bottom-0 bg-dark-card border-t border-gray-800 mt-6 -mx-4 px-4 py-3 sm:static sm:border-0 sm:bg-transparent sm:px-0 sm:py-0">
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="w-full sm:w-auto px-4 py-2 rounded-lg border border-gray-800 text-gray-300 hover:bg-gray-800/50"
                >
                  Cancel
                </button>
                {formData.status === 'scheduled' && formData.date && (
                  <button
                    type="button"
                    onClick={() => {
                      const date = new Date(formData.date);
                      addToCalendar({
                        title: `Vaccination: ${formData.name}`,
                        description: `Dose ${formData.doseNumber}${
                          formData.provider ? `\nProvider: ${formData.provider}` : ''
                        }${
                          formData.location ? `\nLocation: ${formData.location}` : ''
                        }${
                          formData.notes ? `\nNotes: ${formData.notes}` : ''
                        }`,
                        startDate: date,
                        location: formData.location,
                      });
                    }}
                    className="w-full sm:w-auto px-4 py-2 rounded-lg bg-accent-gold/20 
                             border border-accent-gold/30 hover:bg-accent-gold/30 
                             flex items-center justify-center space-x-2"
                  >
                    <CalendarIcon className="h-5 w-5 text-accent-gold" />
                    <span className="text-accent-gold font-medium">Add to Calendar</span>
                  </button>
                )}
                <button
                  type="submit"
                  className="w-full sm:w-auto px-4 py-2 rounded-lg bg-accent-mint/20 text-accent-mint border border-accent-mint/30 hover:bg-accent-mint/30"
                >
                  {initialData ? 'Update' : 'Save'}
                </button>
              </div>
            </form>
          ) : (
            // Schedule Selection Form
            <div className="space-y-6">
              {/* Country Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Select Your Country's Vaccination Schedule
                </label>
                <select
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                >
                  <option value="">Choose a country...</option>
                  {getAllCountries().map(country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Schedule Display */}
              {selectedCountry && getCountrySchedule(selectedCountry) && (
                <div className="space-y-4">
                  {getCountrySchedule(selectedCountry)?.schedules.map((schedule, index) => (
                    <div key={index} className="bg-dark-bg rounded-lg p-4 border border-gray-800">
                      <h4 className="text-white font-medium mb-2">
                        At {schedule.ageInMonths} months
                      </h4>
                      <div className="grid grid-cols-2 gap-2">
                        {schedule.vaccines.map((vaccine) => (
                          <button
                            key={vaccine}
                            onClick={() => {
                              const dueDate = new Date(Date.now() + (schedule.ageInMonths * 30 * 24 * 60 * 60 * 1000));
                              setEntryMode('manual');
                              setFormData(prev => ({
                                ...prev,
                                name: vaccine,
                                status: 'scheduled',
                                date: dueDate.toISOString().split('T')[0],
                                dueDate: dueDate.toISOString().split('T')[0]
                              }));
                            }}
                            className="text-left p-2 rounded-lg bg-accent-mint/10 border border-accent-mint/20 
                                     hover:bg-accent-mint/20 text-sm text-gray-300 hover:text-white"
                          >
                            {vaccine}
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Dialog.Panel>
      </div>

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </Dialog>
  );
}

export default VaccinationForm; 