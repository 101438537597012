import React from 'react';
import { Vaccination } from '../../types/appointments';
import { format, isBefore, isAfter, addDays } from 'date-fns';
import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon, PencilIcon, TrashIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { addToCalendar } from '../../utils/calendarUtils';

interface VaccinationProgressProps {
  vaccinations: Vaccination[];
  onVaccinationClick?: (vaccination: Vaccination) => void;
  onEdit?: (vaccination: Vaccination) => void;
  onDelete?: (vaccination: Vaccination) => void;
  onToggleStatus?: (vaccination: Vaccination) => void;
}

type VaccinationStatus = 'completed' | 'upcoming' | 'overdue';

export function VaccinationProgress({ vaccinations, onVaccinationClick, onEdit, onDelete, onToggleStatus }: VaccinationProgressProps) {
  const now = new Date();

  const getVaccinationsByStatus = (status: VaccinationStatus) => {
    return vaccinations.filter(vaccination => {
      // Skip deleted vaccinations
      if (!vaccination.id) return false;
      
      const dueDate = vaccination.dueDate ? new Date(vaccination.dueDate) : null;
      const isOverdue = dueDate && isBefore(dueDate, addDays(now, -1));
      
      switch (status) {
        case 'completed':
          return vaccination.status === 'completed';
        case 'upcoming':
          return vaccination.status === 'scheduled' && dueDate && isAfter(dueDate, now);
        case 'overdue':
          return vaccination.status === 'scheduled' && isOverdue;
        default:
          return false;
      }
    });
  };

  const completedVaccinations = getVaccinationsByStatus('completed');
  const upcomingVaccinations = getVaccinationsByStatus('upcoming');
  const overdueVaccinations = getVaccinationsByStatus('overdue');

  // Only count active (non-deleted) vaccinations
  const activeVaccinations = vaccinations.filter(v => v.id);
  const totalRequired = activeVaccinations.length;
  const completedCount = completedVaccinations.length;
  const progressPercentage = totalRequired > 0 ? Math.round((completedCount / totalRequired) * 100) : 0;

  const VaccinationCard = ({ vaccination }: { vaccination: Vaccination }) => (
    <div 
      key={vaccination.id} 
      className="bg-dark-bg rounded-lg p-3 border border-gray-800"
    >
      <div className="flex justify-between items-start">
        <div>
          <h4 className="text-white font-medium">{vaccination.name}</h4>
          <div className="space-y-1 mt-1">
            {vaccination.status === 'completed' ? (
              <div className="text-sm text-gray-400">
                Given on {format(vaccination.date, 'MMM d, yyyy')}
              </div>
            ) : vaccination.dueDate && (
              <div className={`text-sm ${isBefore(new Date(vaccination.dueDate), now) 
                ? 'text-accent-rose' 
                : 'text-accent-gold'}`}>
                Due on {format(vaccination.dueDate, 'MMM d, yyyy')}
              </div>
            )}
            {vaccination.provider && (
              <div className="text-sm text-gray-400">
                Provider: {vaccination.provider}
              </div>
            )}
            {vaccination.location && (
              <div className="text-sm text-gray-400">
                Location: {vaccination.location}
              </div>
            )}
            {vaccination.doses[0]?.notes && (
              <div className="text-sm text-gray-400">
                Notes: {vaccination.doses[0].notes}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end space-y-2">
          <div className="flex items-center space-x-2">
            {vaccination.status === 'scheduled' && vaccination.dueDate && (
              <button
                onClick={() => {
                  const dueDate = new Date(vaccination.dueDate!);
                  addToCalendar({
                    title: `Vaccination: ${vaccination.name}`,
                    description: `Dose ${vaccination.doses[0].doseNumber}${
                      vaccination.provider ? `\nProvider: ${vaccination.provider}` : ''
                    }${
                      vaccination.location ? `\nLocation: ${vaccination.location}` : ''
                    }`,
                    startDate: dueDate,
                    location: vaccination.location,
                  });
                }}
                className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-gold"
                title="Add to calendar"
              >
                <CalendarIcon className="h-5 w-5" />
              </button>
            )}
            <button
              onClick={() => onToggleStatus?.(vaccination)}
              className={`p-2 rounded-lg transition-colors ${
                vaccination.status === 'completed'
                  ? 'text-accent-mint hover:bg-accent-mint/20'
                  : 'text-accent-gold hover:bg-accent-gold/20'
              }`}
              title={vaccination.status === 'completed' ? 'Mark as not taken' : 'Mark as taken'}
            >
              <CheckCircleIcon className="h-5 w-5" />
            </button>
            <button
              onClick={() => onEdit?.(vaccination)}
              className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-lavender"
              title="Edit vaccination"
            >
              <PencilIcon className="h-5 w-5" />
            </button>
            <button
              onClick={() => onDelete?.(vaccination)}
              className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-rose"
              title="Delete vaccination"
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
          <div className={`text-xs px-2 py-1 rounded-full
            ${vaccination.status === 'completed' 
              ? 'bg-accent-mint/20 text-accent-mint' 
              : isBefore(new Date(vaccination.dueDate || 0), now)
                ? 'bg-accent-rose/20 text-accent-rose'
                : 'bg-accent-gold/20 text-accent-gold'}`}
          >
            Dose {vaccination.doses[0].doseNumber}
          </div>
          {vaccination.reminder && (
            <div className="text-xs text-accent-lavender bg-accent-lavender/20 px-2 py-1 rounded-full">
              Reminder Set
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Progress Overview */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-accent-mint font-medium">Overall Progress</h3>
          <span className="text-sm text-gray-400">
            {completedCount} of {totalRequired} vaccinations completed
          </span>
        </div>
        <div className="w-full h-2 bg-gray-800 rounded-full overflow-hidden">
          <div 
            className="h-full bg-accent-mint transition-all duration-500"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
        <div className="mt-3 grid grid-cols-3 gap-2 text-center text-sm">
          <div>
            <div className="text-accent-mint">{completedVaccinations.length}</div>
            <div className="text-gray-400">Completed</div>
          </div>
          <div>
            <div className="text-accent-gold">{upcomingVaccinations.length}</div>
            <div className="text-gray-400">Upcoming</div>
          </div>
          <div>
            <div className="text-accent-rose">{overdueVaccinations.length}</div>
            <div className="text-gray-400">Overdue</div>
          </div>
        </div>
      </div>

      {/* Completed Vaccinations */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="flex items-center space-x-2 mb-3">
          <CheckCircleIcon className="h-5 w-5 text-accent-mint" />
          <h3 className="text-accent-mint font-medium">Completed</h3>
        </div>
        <div className="space-y-3">
          {completedVaccinations.length === 0 ? (
            <p className="text-gray-400 text-sm">No completed vaccinations yet.</p>
          ) : (
            completedVaccinations.map(vaccination => (
              <VaccinationCard key={vaccination.id} vaccination={vaccination} />
            ))
          )}
        </div>
      </div>

      {/* Upcoming Vaccinations */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="flex items-center space-x-2 mb-3">
          <ClockIcon className="h-5 w-5 text-accent-gold" />
          <h3 className="text-accent-gold font-medium">Upcoming</h3>
        </div>
        <div className="space-y-3">
          {upcomingVaccinations.length === 0 ? (
            <p className="text-gray-400 text-sm">No upcoming vaccinations scheduled.</p>
          ) : (
            upcomingVaccinations.map(vaccination => (
              <VaccinationCard key={vaccination.id} vaccination={vaccination} />
            ))
          )}
        </div>
      </div>

      {/* Overdue Vaccinations */}
      {overdueVaccinations.length > 0 && (
        <div className="bg-dark-card rounded-lg p-4 border border-accent-rose/30">
          <div className="flex items-center space-x-2 mb-3">
            <ExclamationCircleIcon className="h-5 w-5 text-accent-rose" />
            <h3 className="text-accent-rose font-medium">Overdue</h3>
          </div>
          <div className="space-y-3">
            {overdueVaccinations.map(vaccination => (
              <VaccinationCard key={vaccination.id} vaccination={vaccination} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
} 