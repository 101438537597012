import React from 'react';
import { motion } from 'framer-motion';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AIChatSection from './AIChatSection';

function Learning() {
  const renderDisclaimer = () => {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-accent-rose/10 border border-accent-rose/20 rounded-lg p-4 mb-4"
      >
        <div className="flex items-start space-x-3">
          <ExclamationTriangleIcon className="h-5 w-5 text-accent-rose flex-shrink-0 mt-0.5" />
          <div>
            <h4 className="text-sm font-medium text-accent-rose mb-1">Disclaimer</h4>
            <p className="text-sm text-gray-400">
              AI responses are generated automatically and should not be considered medical advice. Always verify information with qualified healthcare professionals.
            </p>
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-lavender">
          Ask AI Assistant
        </h2>
        <p className="text-gray-400 mt-2">
          Get instant answers to your parenting questions
        </p>
      </div>

      {/* Content Section */}
      <div className="space-y-4">
        {/* Disclaimer */}
        {renderDisclaimer()}

        {/* AI Chat */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <AIChatSection />
        </motion.div>
      </div>
    </div>
  );
}

export default Learning; 