import { TrackingEntry } from '../types/tracking';
import { WellnessEntry } from '../types/wellness';
import { HealthType, HealthEntry } from '../types/health';
import { VictoryEntry } from '../types/victory';
import { firebaseStorage } from './firebaseStorage';
import { auth } from './firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { AdminUserData, UserActivityStats, AdminStats, EntryWithType } from '../types/admin';
import { HealthConcern } from '../types/healthConcerns';
import { Appointment, Vaccination } from '../types/appointments';

// Update the interfaces to include userId
interface TrackingEntryWithUser extends TrackingEntry {
  userId: string;
}

interface WellnessEntryWithUser extends WellnessEntry {
  userId: string;
}

interface HealthEntryWithUser extends HealthEntry {
  userId: string;
}

interface VictoryEntryWithUser extends VictoryEntry {
  userId: string;
}

// Add this interface at the top
interface UserData {
  babyBirthDate?: number;
  // Add other user data fields as needed
}

// Add these types at the top
interface AdminUser {
  id: string;
  email: string;
  displayName: string;
  babyName?: string;
  lastActive: number;
  babyBirthDate?: number;
}

// Update the TrackingType to include health and wellness
type ExtendedTrackingType = 'feeding' | 'sleep' | 'diaper' | 'health' | 'wellness';

class StorageService {
  private checkAuth() {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('User not authenticated');
    return userId;
  }

  // Baby Tracking Methods
  async getEntries(type: TrackingEntry['type']) {
    const userId = this.checkAuth();
    return firebaseStorage.getEntries(type, userId);
  }

  async addEntry(entry: Omit<TrackingEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addEntry({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async deleteEntry(entryId: string) {
    this.checkAuth(); // Verify user is authenticated
    await firebaseStorage.deleteEntry(entryId);
  }

  // Wellness Methods
  async getWellnessEntries(type: WellnessEntry['type']) {
    const userId = this.checkAuth();
    return firebaseStorage.getWellnessEntries(type, userId);
  }

  async addWellnessEntry(entry: Omit<WellnessEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addWellnessEntry({
      ...entry,
      userId
    });
  }

  async updateWellnessEntry(entry: WellnessEntry) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.updateWellnessEntry(entry);
  }

  async deleteWellnessEntry(entryId: string) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.deleteWellnessEntry(entryId);
  }

  // Health Methods
  async getHealthEntries(type: HealthType) {
    const userId = this.checkAuth();
    return firebaseStorage.getHealthEntries(type, userId);
  }

  async addHealthEntry(entry: Omit<HealthEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addHealthEntry({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async updateHealthEntry(entry: HealthEntry) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.updateHealthEntry(entry);
  }

  async deleteHealthEntry(entry: HealthEntry) {
    const userId = this.checkAuth();
    return firebaseStorage.deleteHealthEntry(entry.id, userId);
  }

  // Victory Methods
  async getVictories() {
    const userId = this.checkAuth();
    return firebaseStorage.getVictories(userId);
  }

  async addVictory(entry: Omit<VictoryEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addVictory({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async deleteVictory(victoryId: string) {
    const userId = this.checkAuth();
    return firebaseStorage.deleteVictory(victoryId, userId);
  }

  async shareVictory(victoryId: string) {
    const userId = this.checkAuth();
    return firebaseStorage.updateVictory(victoryId, { shared: true });
  }

  // Add these methods to your existing storage service
  async getAllUsers() {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      lastActive: doc.data().lastActive?.toMillis() || 0
    }));
  }

  async getAllEntries() {
    const entriesRef = collection(db, 'tracking');
    const snapshot = await getDocs(entriesRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as EntryWithType[];
  }

  async getAdminStats(): Promise<AdminStats> {
    try {
      const [usersData, entries] = await Promise.all([
        this.getAllUsers(),
        this.getAllEntries()
      ]);

      const users = usersData as AdminUser[]; // Cast to correct type
      const now = Date.now();
      const sevenDaysAgo = now - (7 * 24 * 60 * 60 * 1000);

      const userActivity: UserActivityStats[] = users.map(user => ({
        userId: user.id,
        email: user.email || 'No email',
        name: user.displayName || 'Anonymous',
        babyName: user.babyName,
        lastActive: user.lastActive,
        totalEntries: entries.filter(e => e.userId === user.id).length,
        entryTypes: {
          feeding: entries.filter(e => e.userId === user.id && e.type === 'feeding').length,
          sleep: entries.filter(e => e.userId === user.id && e.type === 'sleep').length,
          diaper: entries.filter(e => e.userId === user.id && e.type === 'diaper').length,
          health: entries.filter(e => e.userId === user.id && (e.type as ExtendedTrackingType) === 'health').length,
          wellness: entries.filter(e => e.userId === user.id && (e.type as ExtendedTrackingType) === 'wellness').length
        },
        recentActivity: this.getEntriesOverTime(entries.filter(e => e.userId === user.id)),
        averageEntriesPerDay: +(entries.filter(e => e.userId === user.id).length / 30).toFixed(1)
      }));

      const activeUsers = users.filter(u => u.lastActive > sevenDaysAgo);
      const newUsers = users.filter(u => u.lastActive > sevenDaysAgo && !u.babyBirthDate);

      return {
        totalUsers: users.length,
        activeUsers: activeUsers.length,
        newUsersLast7Days: newUsers.length,
        totalEntries: {
          feeding: entries.filter(e => e.type === 'feeding').length,
          sleep: entries.filter(e => e.type === 'sleep').length,
          diaper: entries.filter(e => e.type === 'diaper').length,
        },
        entriesOverTime: this.getEntriesOverTime(entries),
        userActivity,
        dailyAverages: {
          feeding: +(entries.filter(e => e.type === 'feeding').length / 30).toFixed(1),
          sleep: +(entries.filter(e => e.type === 'sleep').length / 30).toFixed(1),
          diaper: +(entries.filter(e => e.type === 'diaper').length / 30).toFixed(1),
        },
        peakActivityHours: this.getPeakActivityHours(entries)
      };
    } catch (error) {
      console.error('Error getting admin stats:', error);
      throw error;
    }
  }

  private getEntriesOverTime(entries: EntryWithType[]) {
    const last30Days = Array.from({ length: 30 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toISOString().split('T')[0];
    }).reverse();

    return last30Days.map(date => {
      const dayEntries = entries.filter(e => 
        new Date(e.timestamp).toISOString().split('T')[0] === date
      );

      return {
        date,
        count: dayEntries.length,
        feeding: dayEntries.filter(e => e.type === 'feeding').length,
        sleep: dayEntries.filter(e => e.type === 'sleep').length,
        diaper: dayEntries.filter(e => e.type === 'diaper').length,
      };
    });
  }

  private getMostActiveTime(entries: EntryWithType[]): string {
    const hourCounts = entries.reduce((acc, entry) => {
      const hour = new Date(entry.timestamp).getHours();
      acc[hour] = (acc[hour] || 0) + 1;
      return acc;
    }, {} as Record<number, number>);

    const mostActiveHour = Object.entries(hourCounts)
      .sort((a, b) => b[1] - a[1])[0]?.[0] || '0';

    return `${mostActiveHour}:00`;
  }

  private getPeakActivityHours(entries: EntryWithType[]): Array<{ hour: number; count: number }> {
    const hourCounts = entries.reduce((acc, entry) => {
      const hour = new Date(entry.timestamp).getHours();
      acc[hour] = (acc[hour] || 0) + 1;
      return acc;
    }, {} as Record<number, number>);

    return Object.entries(hourCounts)
      .map(([hour, count]) => ({ hour: +hour, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
  }

  // Add these new methods
  async getHealthConcerns(): Promise<HealthConcern[]> {
    const userId = this.checkAuth();
    return firebaseStorage.getHealthConcerns(userId);
  }

  async addHealthConcern(concern: Omit<HealthConcern, 'id' | 'userId'>): Promise<HealthConcern> {
    const userId = this.checkAuth();
    return firebaseStorage.addHealthConcern({
      ...concern,
      userId,
      date: Date.now()
    });
  }

  async updateHealthConcern(concern: HealthConcern): Promise<void> {
    this.checkAuth();
    return firebaseStorage.updateHealthConcern(concern);
  }

  async deleteHealthConcern(concernId: string): Promise<void> {
    this.checkAuth();
    return firebaseStorage.deleteHealthConcern(concernId);
  }

  async getAppointments(): Promise<Appointment[]> {
    const userId = this.checkAuth();
    return firebaseStorage.getAppointments(userId);
  }

  async addAppointment(appointment: Omit<Appointment, 'id' | 'userId'>): Promise<Appointment> {
    const userId = this.checkAuth();
    return firebaseStorage.addAppointment({
      ...appointment,
      userId
    });
  }

  async updateAppointment(appointment: Appointment): Promise<void> {
    this.checkAuth();
    return firebaseStorage.updateAppointment(appointment);
  }

  async deleteAppointment(appointmentId: string): Promise<void> {
    this.checkAuth();
    return firebaseStorage.deleteAppointment(appointmentId);
  }

  async getVaccinations(): Promise<Vaccination[]> {
    const userId = this.checkAuth();
    return firebaseStorage.getVaccinations(userId);
  }

  async addVaccination(vaccination: Omit<Vaccination, 'id' | 'userId'>): Promise<Vaccination> {
    const userId = this.checkAuth();
    console.log('Adding vaccination with userId:', userId);
    console.log('Vaccination data:', vaccination);
    
    try {
      const result = await firebaseStorage.addVaccination({
        ...vaccination,
        userId
      });
      console.log('Vaccination added successfully:', result);
      return result;
    } catch (error) {
      console.error('Error in addVaccination:', error);
      throw error;
    }
  }

  async updateVaccination(vaccination: Vaccination): Promise<void> {
    this.checkAuth();
    console.log('Updating vaccination:', vaccination);
    
    try {
      await firebaseStorage.updateVaccination(vaccination);
      console.log('Vaccination updated successfully');
    } catch (error) {
      console.error('Error in updateVaccination:', error);
      throw error;
    }
  }

  async deleteVaccination(vaccinationId: string): Promise<void> {
    this.checkAuth();
    return firebaseStorage.deleteVaccination(vaccinationId);
  }

  async getUserData(userId: string | undefined): Promise<UserData | null> {
    if (!userId) return null;
    try {
      const userData = await firebaseStorage.getUserData(userId);
      return userData;
    } catch (error) {
      console.error('Error getting user data:', error);
      return null;
    }
  }
}

export const storage = new StorageService();
