import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  HeartIcon, 
  SparklesIcon, 
  MoonIcon, 
  ClockIcon,
  ChatBubbleLeftRightIcon,
  AcademicCapIcon,
  ChartBarIcon,
  BookOpenIcon,
  StarIcon,
  BeakerIcon
} from '@heroicons/react/24/outline';
import { FeatureShowcase } from './previews/FeatureShowcase';
import { PhoneMockup } from './previews/PhoneMockup';
import { WellnessPreview } from './previews/WellnessPreview';
import { TrackingFormPreview } from './previews/TrackingFormPreview';
import { StatsPreview } from './previews/StatsPreview';
import { LearningPreview } from './previews/LearningPreview';
import { HealthFormPreview } from './previews/HealthFormPreview';

function FeaturePhones() {
  return (
    <div className="relative w-full overflow-hidden py-10">
      <div className="max-w-7xl mx-auto px-2">
        <div className="relative flex justify-center items-center gap-4 sm:gap-8">
          {/* Outer left */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="w-[200px] sm:w-[240px] -rotate-12 translate-y-8 hidden md:block opacity-80"
          >
            <PhoneMockup>
              <WellnessPreview />
            </PhoneMockup>
          </motion.div>

          {/* Inner left */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="w-[200px] sm:w-[240px] -rotate-6 translate-y-4 hidden sm:block opacity-90"
          >
            <PhoneMockup>
              <TrackingFormPreview />
            </PhoneMockup>
          </motion.div>

          {/* Center */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="w-[240px] sm:w-[280px] z-10"
          >
            <PhoneMockup>
              <StatsPreview />
            </PhoneMockup>
          </motion.div>

          {/* Inner right */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="w-[200px] sm:w-[240px] rotate-6 translate-y-4 hidden sm:block opacity-90"
          >
            <PhoneMockup>
              <LearningPreview />
            </PhoneMockup>
          </motion.div>

          {/* Outer right */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="w-[200px] sm:w-[240px] rotate-12 translate-y-8 hidden md:block opacity-80"
          >
            <PhoneMockup>
              <HealthFormPreview />
            </PhoneMockup>
          </motion.div>
        </div>

        {/* Gradient overlays */}
        <div className="absolute inset-0 bg-gradient-to-r from-dark-bg via-transparent to-dark-bg pointer-events-none" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-dark-bg pointer-events-none" />
      </div>
    </div>
  );
}

function MobileAppShowcase() {
  return (
    <div className="md:hidden relative -mt-10 overflow-hidden">
      {/* Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-dark-bg via-dark-bg/50 to-dark-bg pointer-events-none" />

      {/* Main Content */}
      <div className="relative">
        {/* Feature Sections */}
        <div className="space-y-24 pb-20"> {/* Increased spacing between sections */}
          {/* Dashboard Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Beautiful Dashboard</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Everything you need, elegantly organized in one place
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px]">
                <PhoneMockup>
                  <StatsPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Baby Tracking Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Smart Baby Tracking</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Effortlessly track feeds, sleep, and changes
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[-5deg]">
                <PhoneMockup>
                  <TrackingFormPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Wellness Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Mom's Wellness</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Because your wellbeing matters too
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[5deg]">
                <PhoneMockup>
                  <WellnessPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Health Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Health & Growth</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Never miss important milestones
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[-3deg]">
                <PhoneMockup>
                  <HealthFormPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Learning Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Learn & Grow Together</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Gentle guidance and support for your parenting journey
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[3deg]">
                <PhoneMockup>
                  <LearningPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Action */}
        <div className="text-center px-6 py-12 bg-gradient-to-t from-dark-bg to-transparent">
          <Link
            to="/signup"
            className="inline-block w-full px-6 py-3 bg-accent-mint text-dark-bg rounded-xl 
                     text-lg font-medium hover:bg-accent-mint/90 transition-colors"
          >
            Start Your Journey
          </Link>
        </div>
      </div>
    </div>
  );
}

function FloatingElements() {
  return (
    <>
      {/* Hero Section Elements - Smaller & fewer on mobile */}
      <motion.div 
        className="absolute top-32 left-[10%] text-accent-lavender/10"
        animate={{ 
          y: [-10, 10, -10],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">👶</span>
      </motion.div>

      <motion.div 
        className="absolute top-40 right-[15%] text-accent-mint/10"
        animate={{ 
          y: [10, -10, 10],
          rotate: [5, -5, 5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-xl md:text-3xl">🍼</span>
      </motion.div>

      {/* Features Section Elements - Some hidden on mobile */}
      <motion.div 
        className="absolute top-[40%] left-[5%] text-accent-rose/10 hidden md:block"
        animate={{ 
          scale: [1, 1.1, 1],
          rotate: [0, -10, 0]
        }}
        transition={{ 
          duration: 6,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <HeartIcon className="w-16 h-16" />
      </motion.div>

      {/* Middle Section Elements - Smaller on mobile */}
      <motion.div 
        className="absolute top-[60%] left-[20%] text-accent-mint/10"
        animate={{ 
          y: [-15, 15, -15],
          x: [-5, 5, -5]
        }}
        transition={{ 
          duration: 10,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">🌙</span>
      </motion.div>

      <motion.div 
        className="absolute top-[65%] right-[25%] text-accent-peach/10"
        animate={{ 
          y: [15, -15, 15],
          x: [5, -5, 5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <ClockIcon className="w-8 h-8 md:w-14 md:h-14" />
      </motion.div>

      {/* Bottom Section Elements - Some hidden on mobile */}
      <motion.div 
        className="absolute bottom-[20%] left-[15%] text-accent-lavender/10 hidden md:block"
        animate={{ 
          scale: [1, 1.15, 1],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <SparklesIcon className="w-12 h-12" />
      </motion.div>

      {/* Additional Scattered Elements - Fewer & smaller on mobile */}
      <motion.div 
        className="absolute top-[30%] left-[30%] text-accent-gold/10"
        animate={{ 
          opacity: [0.5, 1, 0.5],
          scale: [1, 1.1, 1]
        }}
        transition={{ 
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-base md:text-2xl">✨</span>
      </motion.div>

      <motion.div 
        className="absolute top-[70%] right-[30%] text-accent-rose/10 hidden md:block"
        animate={{ 
          opacity: [0.5, 1, 0.5],
          scale: [1, 1.1, 1]
        }}
        transition={{ 
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-xl md:text-3xl">🧸</span>
      </motion.div>

      {/* Add these new elements */}
      <motion.div 
        className="absolute top-[45%] right-[10%] text-accent-rose/10 hidden md:block"
        animate={{ 
          y: [-10, 10, -10],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 9,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-xl md:text-3xl">🎀</span>
      </motion.div>

      <motion.div 
        className="absolute bottom-[30%] left-[25%] text-accent-mint/10"
        animate={{ 
          scale: [1, 1.1, 1],
          rotate: [0, 5, 0]
        }}
        transition={{ 
          duration: 6,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-base md:text-2xl">💝</span>
      </motion.div>
    </>
  );
}

function MotherChildIllustration() {
  return (
    <svg 
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="opacity-80"
    >
      {/* Mother's head with more detail */}
      <path 
        d="M24 8C19 8 15 12 15 17C15 22 19 26 24 26C29 26 33 22 33 17C33 12 29 8 24 8Z"
        className="fill-accent-lavender/30" 
      />
      
      {/* Mother's arms cradling baby */}
      <path 
        d="M18 24C18 24 20 32 24 32C28 32 30 24 30 24"
        className="stroke-accent-rose/30" 
        strokeWidth="2"
        strokeLinecap="round"
      />
      
      {/* Baby in arms - smaller to represent newborn */}
      <path
        d="M22 26C22 26 24 30 26 26"
        className="stroke-accent-mint/30"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle 
        cx="24" 
        cy="28" 
        r="2" 
        className="fill-accent-mint/30" 
      />
      
      {/* Mother's loving gaze down at baby */}
      <path
        d="M22 16C22 16 23 17 24 17C25 17 26 16 26 16"
        className="stroke-accent-rose/30"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function LandingPage() {
  const [showStory, setShowStory] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false);
  
  const testimonials = [
    {
      quote: "Perfect for those 3 AM feeds when I'm too tired to remember anything...",
      author: "Sarah, new mum",
      color: "lavender"
    },
    {
      quote: "Finally, I can track everything my pediatrician asks about!",
      author: "Emma",
      color: "mint"
    },
    {
      quote: "The mood tracking helped me spot my postpartum anxiety early...",
      author: "Aisha",
      color: "rose"
    },
    {
      quote: "I can check baby's last feed without waking my wife. Lifesaver!",
      author: "James",
      color: "gold"
    },
    {
      quote: "Never miss a vaccination appointment anymore...",
      author: "Priya",
      color: "peach"
    },
    {
      quote: "Sleep pattern tracking helped us establish a better routine",
      author: "Maya",
      color: "lavender"
    },
    {
      quote: "The AI chat answers my questions when I'm up late worrying...",
      author: "Lisa",
      color: "mint"
    },
    {
      quote: "Helps me share updates with my partner during night shifts",
      author: "Nina",
      color: "rose"
    },
    {
      quote: "Love how it reminds me to take care of myself too",
      author: "Rachel",
      color: "gold"
    },
    {
      quote: "Finally understanding my baby's sleep patterns!",
      author: "Mei",
      color: "peach"
    },
    {
      quote: "The feeding timer is exactly what I needed",
      author: "Sophie",
      color: "lavender"
    },
    {
      quote: "Makes doctor visits so much easier with all the data...",
      author: "Olivia",
      color: "mint"
    },
    {
      quote: "The feeding timer helps me stay organized at night",
      author: "Alex",
      color: "rose"
    },
    {
      quote: "Love how easy it is to log everything in one place",
      author: "Maria",
      color: "gold"
    },
    {
      quote: "Helps me stay consistent with baby's routine",
      author: "Jenny",
      color: "peach"
    },
    {
      quote: "Helps me remember which breast to start with next",
      author: "Zara",
      color: "lavender"
    },
    {
      quote: "Love having all baby's info organized in one place",
      author: "Tanya",
      color: "mint"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Save original styles
    const originalBg = document.body.style.backgroundColor;
    const originalOverscroll = document.body.style.overscrollBehavior;
    
    // Apply dark background and prevent overscroll
    document.body.style.backgroundColor = '#0A0A0B';
    document.body.style.overscrollBehavior = 'none';
    
    // Cleanup when component unmounts
    return () => {
      document.body.style.backgroundColor = originalBg;
      document.body.style.overscrollBehavior = originalOverscroll;
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
      const currentProgress = (window.pageYOffset / totalScroll) * 100;
      setScrollProgress(currentProgress);
      setShowBackToTop(window.pageYOffset > 800);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-dark-bg text-white relative overflow-hidden">
      {/* Scroll Progress Bar */}
      <div 
        className="fixed top-0 left-0 w-full h-[2px] bg-gray-800 z-50"
        style={{ 
          backgroundImage: `linear-gradient(to right, #E9B8FF ${scrollProgress}%, transparent 0)` 
        }} 
      />
      
      <FloatingElements />
      
      <div className="absolute inset-0 bg-gradient-to-b from-accent-lavender/5 via-transparent to-transparent pointer-events-none" />
      
      {/* Header/Nav */}
      <nav className="fixed top-0 left-0 right-0 bg-dark-card/95 backdrop-blur-xl border-b border-gray-800 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <HeartIcon className="h-8 w-8 text-accent-lavender" />
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-accent-lavender via-accent-rose to-accent-mint bg-clip-text text-transparent">
                NurturingMom
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <Link 
                to="/login"
                className="px-4 py-2 bg-dark-card text-accent-lavender border border-accent-lavender/30 
                         hover:bg-accent-lavender/10 rounded-lg transition-colors"
              >
                Log In
              </Link>
              <Link 
                to="/signup"
                className="px-4 py-2 bg-accent-mint text-dark-bg rounded-lg 
                         hover:bg-accent-mint/90 transition-colors"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section - Adjusted mobile padding */}
      <section className="pt-28 sm:pt-20 pb-8"> {/* Increased padding-top for mobile only */}
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4 sm:space-y-6"
          >
            <h1 className="text-3xl sm:text-5xl font-bold leading-tight">
              {/* Desktop Version */}
              <span className="hidden sm:block">Never Wonder "Am I Doing This Right?"</span>
              <span className="hidden sm:block bg-gradient-to-r from-accent-lavender to-accent-rose bg-clip-text text-transparent">
                Track, Learn, and Trust Your Journey
              </span>
              
              {/* Mobile Version - Simplified and stacked */}
              <span className="sm:hidden">
                <span className="block mb-2">Never Wonder</span>
                <span className="block mb-2 bg-gradient-to-r from-accent-lavender to-accent-rose bg-clip-text text-transparent">
                  "Am I Doing This Right?"
                </span>
              </span>
            </h1>
            <p className="text-base sm:text-xl text-gray-400 max-w-xl mx-auto mt-4 sm:mt-6 px-4">
              From 3 AM feedings to tracking sleep patterns, we help you stay organized and confident in those precious first months. Because you've got this, mama. 💝
            </p>

            {/* Single Rotating Testimonial */}
            <div className="relative h-20 mt-4 mb-2 px-4">
              <AnimatePresence mode="wait">
                {testimonials.map((testimonial, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ 
                      opacity: index === currentTestimonial ? 0.7 : 0,
                      y: index === currentTestimonial ? 0 : 10
                    }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.7 }}
                    className={`absolute inset-0 flex-shrink-0 mx-auto max-w-[260px] p-3 rounded-lg 
                               bg-dark-card/10 border border-accent-${testimonial.color}/5
                               ${index === currentTestimonial ? 'block' : 'hidden'}`}
                  >
                    <motion.div
                      animate={{ y: [-1, 1, -1] }}
                      transition={{ 
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      <p className="text-[11px] text-gray-400/80 italic">{testimonial.quote}</p>
                      <p className="text-[9px] text-accent-mint/70 mt-1">✨ {testimonial.author}</p>
                    </motion.div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>

            <div className="flex flex-col items-center space-y-6"> {/* Increased from space-y-3 */}
              <Link
                to="/signup"
                className="px-6 sm:px-8 py-3 bg-accent-mint text-dark-bg rounded-lg 
                         text-base sm:text-lg font-medium hover:bg-accent-mint/90 
                         transform transition-all duration-300 hover:scale-105
                         shadow-lg hover:shadow-accent-mint/20"
              >
                Join Other Mums
              </Link>
              
              {/* Story Toggle - Added more space above */}
              <button
                onClick={() => setShowStory(!showStory)}
                className="flex items-center text-accent-mint hover:text-accent-mint/80 transition-colors"
              >
                <span className="text-sm sm:text-base font-medium">
                  {showStory ? 'Hide Our Story' : 'Why We Built This'}
                </span>
                <svg
                  className={`w-4 h-4 ml-2 transform transition-transform ${showStory ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {/* Collapsible Story Text - Fixed mobile spacing and overflow */}
              <motion.div
                initial={false}
                animate={{ 
                  height: showStory ? 'auto' : 0, 
                  opacity: showStory ? 1 : 0 
                }}
                transition={{
                  height: { duration: 0.4 },
                  opacity: { duration: 0.3, delay: showStory ? 0.2 : 0 }
                }}
                className="overflow-hidden max-w-2xl mx-auto px-4 sm:px-0"
              >
                <p className="text-xs sm:text-sm text-gray-300 leading-relaxed mt-4 mb-6">
                  When my wife, Bibie, and I brought our baby boy, Eden, home, we were thrilled but also a bit overwhelmed. There was so much to keep track of, and we wanted to make sure we were doing everything right.
                  <br /><br />
                  So, we built this app together to help us out. It made things easier for our family, and we hope it can help yours too.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Mobile: See It In Action - Adjusted spacing */}
      <div className="md:hidden -mt-4"> {/* Reduced negative margin */}
        <FeatureShowcase />
      </div>

      {/* Desktop: Product Showcase - Reduced spacing */}
      <div className="hidden md:block">
        <div className="py-2"> {/* Reduced from py-4 */}
          <FeaturePhones />
        </div>
      </div>

      {/* Features Grid - Adjusted formatting */}
      <section className="py-8 px-4 sm:px-6 lg:px-8 bg-dark-card/50">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-6">
            <h2 className="text-2xl sm:text-3xl font-bold mb-2">Designed for Your New Journey</h2>
            <p className="text-sm sm:text-base text-gray-400 max-w-2xl mx-auto">
              Everything you need to feel confident and supported in your early motherhood days
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Easy Feeding & Sleep Tracking",
                desc: "Those middle-of-the-night moments can feel foggy. Track feeds, sleep, and changes with simple taps - so you can focus on those precious cuddles instead of trying to remember everything.",
                icon: ClockIcon,
                color: 'peach'
              },
              {
                title: "Mom's Self-Care",
                desc: "Motherhood is beautiful, but intense. Track your rest, mood, and energy levels to help you understand your own needs during this special time.",
                icon: SparklesIcon,
                color: 'mint'
              },
              {
                title: "24/7 AI Support",
                desc: "Have questions at 3 AM? Our AI assistant is always here to help with feeding tips, sleep questions, and general support - like having a knowledgeable friend always by your side.",
                icon: ChatBubbleLeftRightIcon,
                color: 'gold'
              },
              {
                title: "Health Concerns Log",
                desc: "Keep track of any health concerns you notice about your baby or yourself - perfect for remembering what to discuss at your next doctor's visit.",
                icon: BookOpenIcon,
                color: 'rose'
              },
              {
                title: "Pattern Insights",
                desc: "Notice your little one's natural patterns in feeding and sleep with our simple charts. Understanding these patterns helps make each day flow a little smoother.",
                icon: ChartBarIcon,
                color: 'lavender'
              },
              {
                title: "Important Dates",
                desc: "Log upcoming vaccination appointments, hospital visits, and checkups. A simple way to keep track of your little one's important healthcare dates.",
                icon: StarIcon,
                color: 'peach'
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className={`bg-dark-card p-6 rounded-xl border border-gray-800 hover:border-accent-${feature.color}/30 transition-all duration-300`}
              >
                <div className={`p-3 bg-accent-${feature.color}/10 w-fit rounded-lg mb-4`}>
                  <feature.icon className={`h-6 w-6 text-accent-${feature.color}`} />
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Desktop: See It In Action */}
      <section className="hidden md:block py-8">
        <FeatureShowcase />
      </section>

      {/* CTA Section - Adjusted formatting */}
      <section className="pt-6 pb-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-4 sm:space-y-6"
          >
            <h2 className="text-2xl sm:text-3xl font-bold">Begin Your Journey Today</h2>
            <p className="text-sm sm:text-base text-gray-400 max-w-2xl mx-auto">
              Join thousands of moms making their early motherhood journey more organized and beautiful.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-4">
              <Link
                to="/signup"
                className="px-6 sm:px-8 py-3 bg-accent-mint text-dark-bg rounded-lg 
                         text-base sm:text-lg font-medium hover:bg-accent-mint/90 
                         transform transition-all duration-300 hover:scale-105
                         shadow-lg hover:shadow-accent-mint/20"
              >
                Join Other Mums
              </Link>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Social Proof Section */}
      <section className="py-12 px-4 sm:px-6 lg:px-8 bg-dark-card/30">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-8">Loved by Parents Like You</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                quote: "When we first brought Noah home, I was so tired I could barely remember when I last fed him. This app has been a lifesaver for tracking everything, especially during those foggy 3 AM feeds!",
                author: "Sarah, mum of 2 month old",
                color: "lavender"
              },
              {
                quote: "At every checkup, they'd ask about sleep patterns and nappy changes. Now I just open the app and have everything right there. It's made those early weeks so much easier to manage.",
                author: "Emma, first-time mum",
                color: "mint"
              },
              {
                quote: "Between the sleep deprivation and recovery, I was struggling to keep track of my own rest and baby's routine. This app helps me stay on top of both - it's like having a helping hand.",
                author: "Lucy, mum of newborn",
                color: "rose"
              },
              {
                quote: "As a dad trying to help during night feeds, this app has been brilliant. I can check when the last feed was without waking my wife, and track everything while she rests.",
                author: "James, dad of 6 week old",
                color: "gold"
              },
              {
                quote: "Being able to share the app with my husband has been amazing. When he takes the night shift, he can see when the last feed was, and I can wake up knowing exactly how our little one's night went.",
                author: "Chioma, mum of 3 month old",
                color: "peach"
              },
              {
                quote: "With my second baby, I started using the journal to write down my thoughts and feelings. It's become such a special way to document this journey, not just for baby but for me too.",
                author: "Aisha, mum of 2",
                color: "mint"
              }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className={`p-6 rounded-xl border border-gray-800 hover:border-accent-${testimonial.color}/30 transition-all duration-300`}
              >
                <p className="text-gray-300 mb-4">"{testimonial.quote}"</p>
                <p className="text-sm text-accent-mint">{testimonial.author}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer - Simplified */}
      <footer className="bg-dark-card border-t border-gray-800 py-3">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-gray-400 mb-1.5">
            © 2024 NurturingMom. All rights reserved.
          </p>
          
          {/* Dedication - Without illustration */}
          <p 
            className="font-['Crimson_Text'] italic text-sm sm:text-base
                     bg-gradient-to-r from-accent-lavender via-accent-rose to-accent-mint 
                     bg-clip-text text-transparent"
          >
            Made with love for Bibie & Eden
          </p>
        </div>
      </footer>
    </div>
  );
} 