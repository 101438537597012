import React, { useState } from 'react';
import { PlayIcon, PauseIcon, StopIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { TrackingType } from '../../types/tracking';
import { TrackingForm } from '../tracking/TrackingForm';
import { useTimer } from '../../contexts/TimerContext';
import { haptics } from '../../utils/haptics';

export interface QuickTimerProps {
  onComplete: () => void;
}

export function QuickTimer({ onComplete }: QuickTimerProps) {
  const { isRunning, elapsed, startTimer, pauseTimer, stopTimer } = useTimer();
  const [selectedType, setSelectedType] = useState<TrackingType>('feeding');
  const [showForm, setShowForm] = useState(false);
  const [finalDuration, setFinalDuration] = useState<number>(0);

  const handleStop = () => {
    const duration = Math.floor(elapsed / 1000);
    setFinalDuration(duration);
    stopTimer();
    setShowForm(true);
    haptics.success();
  };

  const formatTime = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  const handleFormClose = (success?: boolean) => {
    setShowForm(false);
    if (success) {
      onComplete();
    }
    setFinalDuration(0);
  };

  return (
    <>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-dark-card rounded-lg p-4 border border-gray-800"
      >
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-white">Quick Timer</h3>
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as TrackingType)}
              className="bg-dark-bg text-white border border-gray-800 rounded-lg px-3 py-1"
              disabled={isRunning}
            >
              <option value="feeding">Feeding</option>
              <option value="sleep">Sleep</option>
            </select>
          </div>

          <div className="text-center">
            <div className="text-3xl font-bold text-accent-lavender mb-4">
              {formatTime(elapsed)}
            </div>

            <div className="flex justify-center space-x-4">
              {!isRunning ? (
                <button
                  onClick={() => {
                    startTimer(selectedType);
                    haptics.light();
                  }}
                  className="p-3 rounded-full bg-accent-mint/20 text-accent-mint hover:bg-accent-mint/30 transition-colors"
                >
                  <PlayIcon className="h-6 w-6" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    pauseTimer();
                    haptics.light();
                  }}
                  className="p-3 rounded-full bg-accent-peach/20 text-accent-peach hover:bg-accent-peach/30 transition-colors"
                >
                  <PauseIcon className="h-6 w-6" />
                </button>
              )}
              {elapsed > 0 && (
                <button
                  onClick={handleStop}
                  className="p-3 rounded-full bg-accent-rose/20 text-accent-rose hover:bg-accent-rose/30 transition-colors"
                >
                  <StopIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          </div>
        </div>
      </motion.div>

      <TrackingForm
        isOpen={showForm}
        onClose={handleFormClose}
        type={selectedType}
        initialDuration={finalDuration}
        setToast={onComplete}
      />
    </>
  );
} 