import React, { useState } from 'react';
import { TrackingEntry } from '../../types/tracking';
import { TrendAnalysis } from './TrendAnalysis';
import { TimePatternAnalysis } from './TimePatternAnalysis';
import { motion } from 'framer-motion';

type TimeRange = '7days' | '14days' | '30days' | '90days';

interface Props {
  entries: TrackingEntry[];
  type: 'feeding' | 'sleep' | 'diaper';
}

export function TrackingStats({ entries, type }: Props) {
  const [timeRange, setTimeRange] = useState<TimeRange>('7days');

  const getDailySummary = () => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const todayEntries = entries.filter(entry => {
      const entryDate = new Date(entry.timestamp);
      return entryDate >= today && entryDate < new Date(today.getTime() + 24 * 60 * 60 * 1000);
    });

    // Time periods (using 24-hour format)
    const morning = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 6 && hour < 12;
    });

    const afternoon = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 12 && hour < 18;
    });

    const evening = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 18 || hour < 6;
    });

    const formatDuration = (entries: TrackingEntry[]) => {
      if (type === 'diaper') return entries.length;
      const totalMinutes = entries.reduce((acc, curr) => acc + (curr.duration || 0), 0) / 60;
      return totalMinutes > 60 
        ? `${Math.floor(totalMinutes / 60)}h ${Math.round(totalMinutes % 60)}m`
        : `${Math.round(totalMinutes)}m`;
    };

    const getTypeLabel = () => {
      switch (type) {
        case 'feeding': return 'Feeds';
        case 'sleep': return 'Sleep';
        case 'diaper': return 'Changes';
      }
    };

    const getTypeColor = () => {
      switch (type) {
        case 'feeding': return 'text-accent-peach';
        case 'sleep': return 'text-accent-mint';
        case 'diaper': return 'text-accent-rose';
      }
    };

    return (
      <div className="grid grid-cols-3 gap-3">
        <div className="bg-dark-bg p-3 rounded-lg border border-gray-800">
          <div className="text-sm text-gray-400 mb-1">Morning</div>
          <div className={`text-lg font-medium ${getTypeColor()}`}>
            {formatDuration(morning)}
          </div>
          <div className="text-xs text-gray-500">
            {morning.length} {getTypeLabel()}
          </div>
        </div>
        <div className="bg-dark-bg p-3 rounded-lg border border-gray-800">
          <div className="text-sm text-gray-400 mb-1">Afternoon</div>
          <div className={`text-lg font-medium ${getTypeColor()}`}>
            {formatDuration(afternoon)}
          </div>
          <div className="text-xs text-gray-500">
            {afternoon.length} {getTypeLabel()}
          </div>
        </div>
        <div className="bg-dark-bg p-3 rounded-lg border border-gray-800">
          <div className="text-sm text-gray-400 mb-1">Evening</div>
          <div className={`text-lg font-medium ${getTypeColor()}`}>
            {formatDuration(evening)}
          </div>
          <div className="text-xs text-gray-500">
            {evening.length} {getTypeLabel()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-white">Stats & Trends</h3>
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value as TimeRange)}
          className="bg-dark-bg text-white text-sm rounded-lg px-3 py-1.5 border border-gray-800 
                   focus:outline-none focus:border-accent-lavender/50"
        >
          <option value="7days">Last 7 Days</option>
          <option value="14days">Last 14 Days</option>
          <option value="30days">Last 30 Days</option>
          <option value="90days">Last 90 Days</option>
        </select>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="grid gap-4"
      >
        {/* Daily Summary */}
        <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
          <h4 className="text-sm font-medium text-gray-400 mb-3">Today's Summary</h4>
          {getDailySummary()}
        </div>

        {/* Trend Analysis */}
        <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
          <h4 className="text-sm font-medium text-gray-400 mb-3">Trend Analysis</h4>
          <TrendAnalysis entries={entries} type={type} timeRange={timeRange} />
        </div>

        {/* Time Pattern Analysis */}
        <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
          <h4 className="text-sm font-medium text-gray-400 mb-3">AI Insights</h4>
          <TimePatternAnalysis entries={entries} type={type} />
        </div>
      </motion.div>
    </div>
  );
}
