import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface ToastProps {
  message: string;
  type: 'success' | 'error';
  isVisible: boolean;
}

export function Toast({ message, type, isVisible }: ToastProps) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className={`fixed bottom-20 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg
            ${type === 'success' ? 'bg-accent-mint/20 text-accent-mint border border-accent-mint/30' 
            : 'bg-accent-rose/20 text-accent-rose border border-accent-rose/30'}`}
        >
          {message}
        </motion.div>
      )}
    </AnimatePresence>
  );
}