import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  PlusIcon, 
  PencilIcon, 
  CheckCircleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';
import { Toast } from '../common/Toast';
import { HealthConcernForm } from './HealthConcernForm';
import { HealthConcern } from '../../types/healthConcerns';
import { ConfirmDialog } from '../common/ConfirmDialog';

type SortOption = 'date' | 'severity' | 'status';
type FilterOption = 'all' | 'active' | 'resolved';

export function BabyHealth() {
  const [concerns, setConcerns] = useState<HealthConcern[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [sortBy, setSortBy] = useState<SortOption>('date');
  const [filterBy, setFilterBy] = useState<FilterOption>('all');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConcern, setSelectedConcern] = useState<HealthConcern | undefined>(undefined);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [concernToDelete, setConcernToDelete] = useState<HealthConcern | null>(null);

  useEffect(() => {
    loadConcerns();
  }, []);

  const loadConcerns = async () => {
    try {
      setIsLoading(true);
      const data = await storage.getHealthConcerns();
      setConcerns(data);
    } catch (error) {
      console.error('Error loading health concerns:', error);
      setToast({ message: 'Failed to load health concerns', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const getFilteredAndSortedConcerns = () => {
    let filtered = [...concerns];

    // Apply filters
    if (filterBy !== 'all') {
      filtered = filtered.filter(concern => concern.status === filterBy);
    }

    // Apply sorting
    return filtered.sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return b.date - a.date;
        case 'severity':
          const severityOrder = { high: 0, medium: 1, low: 2 };
          return severityOrder[a.severity] - severityOrder[b.severity];
        case 'status':
          return a.status === 'active' ? -1 : 1;
        default:
          return 0;
      }
    });
  };

  const handleDelete = async (concern: HealthConcern) => {
    try {
      await storage.deleteHealthConcern(concern.id);
      await loadConcerns();
      setToast({ message: 'Concern deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting concern:', error);
      setToast({ message: 'Failed to delete concern', type: 'error' });
    } finally {
      setShowDeleteConfirm(false);
      setConcernToDelete(null);
      setTimeout(() => setToast(null), 3000);
    }
  };

  const handleToggleStatus = async (concern: HealthConcern) => {
    try {
      const newStatus = concern.status === 'active' ? 'resolved' : 'active';
      await storage.updateHealthConcern({
        ...concern,
        status: newStatus,
        date: Date.now()
      });
      await loadConcerns();
      setToast({ 
        message: `Concern marked as ${newStatus}`, 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error updating concern status:', error);
      setToast({ message: 'Failed to update status', type: 'error' });
    }
  };

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-rose">Baby Health Journal</h2>
        <p className="text-gray-400 mt-2">
          Track health concerns and share updates with your healthcare providers
        </p>
      </div>

      {/* Filters and Sort */}
      <div className="flex items-center justify-between gap-4 bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="flex items-center gap-2">
          <select
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value as FilterOption)}
            className="bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-sm text-white"
          >
            <option value="all">All Concerns</option>
            <option value="active">Active</option>
            <option value="resolved">Resolved</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as SortOption)}
            className="bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-sm text-white"
          >
            <option value="date">Latest First</option>
            <option value="severity">By Severity</option>
            <option value="status">By Status</option>
          </select>
        </div>
        <motion.button
          onClick={() => setIsFormOpen(true)}
          className="bg-accent-rose/20 text-accent-rose px-4 py-2 rounded-lg 
                   border border-accent-rose/30 flex items-center space-x-2 
                   hover:bg-accent-rose/30 transition-colors"
        >
          <PlusIcon className="h-5 w-5" />
          <span>Add</span>
        </motion.button>
      </div>

      {/* Concerns List */}
      {isLoading ? (
        <div className="flex items-center justify-center h-40">
          <div className="text-accent-rose animate-pulse">Loading concerns...</div>
        </div>
      ) : concerns.length === 0 ? (
        <div className="text-center py-8 text-gray-400">
          No health concerns recorded yet.
        </div>
      ) : (
        <div className="space-y-4">
          {getFilteredAndSortedConcerns().map((concern) => (
            <motion.div
              key={concern.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-dark-card rounded-lg p-4 border border-gray-800 hover:bg-gray-800/50 transition-colors"
            >
              <div className="flex items-start justify-between">
                <div className="flex-grow">
                  <h3 className="text-lg font-medium text-white">{concern.title}</h3>
                  <p className="text-sm text-gray-400 mt-1">{concern.description}</p>
                  <div className="flex items-center space-x-2 mt-2">
                    <span className={`px-2 py-1 rounded-full text-xs 
                      ${concern.severity === 'high' ? 'bg-accent-rose/20 text-accent-rose' :
                        concern.severity === 'medium' ? 'bg-accent-gold/20 text-accent-gold' :
                        'bg-accent-mint/20 text-accent-mint'}`}>
                      {concern.severity.charAt(0).toUpperCase() + concern.severity.slice(1)}
                    </span>
                    <span className={`px-2 py-1 rounded-full text-xs
                      ${concern.status === 'active' ? 'bg-accent-lavender/20 text-accent-lavender' :
                      'bg-gray-500/20 text-gray-400'}`}>
                      {concern.status.charAt(0).toUpperCase() + concern.status.slice(1)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleToggleStatus(concern)}
                    className={`p-2 hover:bg-gray-800/50 rounded-lg transition-colors 
                      ${concern.status === 'active' ? 'text-accent-mint' : 'text-accent-gold'}`}
                    title={`Mark as ${concern.status === 'active' ? 'resolved' : 'active'}`}
                  >
                    <CheckCircleIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedConcern(concern);
                      setIsFormOpen(true);
                    }}
                    className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-lavender"
                    title="Edit concern"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setConcernToDelete(concern);
                      setShowDeleteConfirm(true);
                    }}
                    className="p-2 hover:bg-gray-800/50 rounded-lg transition-colors text-accent-rose"
                    title="Delete concern"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      )}

      {/* Form Modal */}
      <HealthConcernForm 
        isOpen={isFormOpen}
        onClose={(success) => {
          setIsFormOpen(false);
          setSelectedConcern(undefined);
          if (success) {
            loadConcerns();
            setToast({ message: 'Health concern saved successfully!', type: 'success' });
            setTimeout(() => setToast(null), 3000);
          }
        }}
        initialData={selectedConcern}
      />

      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false);
          setConcernToDelete(null);
        }}
        onConfirm={() => concernToDelete && handleDelete(concernToDelete)}
        title="Delete Health Concern"
        message="Are you sure you want to delete this health concern? This action cannot be undone."
      />

      {/* Toast */}
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default BabyHealth; 