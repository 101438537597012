import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';
import { Appointment } from '../../types/appointments';
import { addToCalendar } from '../../utils/calendarUtils';
import { notificationService } from '../../services/notificationService';

interface AppointmentFormProps {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  initialData?: Appointment;
}

export function AppointmentForm({ isOpen, onClose, initialData }: AppointmentFormProps) {
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    type: initialData?.type || 'checkup',
    date: initialData?.date ? new Date(initialData.date).toISOString().split('T')[0] : '',
    time: initialData?.time || '',
    location: initialData?.location || '',
    provider: initialData?.provider || '',
    notes: initialData?.notes || '',
    completed: initialData?.completed || false
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        title: initialData.title,
        type: initialData.type,
        date: new Date(initialData.date).toISOString().split('T')[0],
        time: initialData.time || '',
        location: initialData.location || '',
        provider: initialData.provider || '',
        notes: initialData.notes || '',
        completed: initialData.completed
      });
    } else {
      setFormData({
        title: '',
        type: 'checkup',
        date: '',
        time: '',
        location: '',
        provider: '',
        notes: '',
        completed: false
      });
    }
  }, [initialData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (initialData) {
        await storage.updateAppointment({
          ...initialData,
          ...formData,
          date: new Date(formData.date).getTime()
        });
      } else {
        await storage.addAppointment({
          ...formData,
          date: new Date(formData.date).getTime(),
          completed: false
        });
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving appointment:', error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-lg w-full rounded-xl bg-dark-card border border-gray-800 p-4 sm:p-6 max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between mb-4 sticky top-0 bg-dark-card z-10 pb-2">
            <Dialog.Title className="text-lg font-medium text-white">
              {initialData ? 'Edit Appointment' : 'New Appointment'}
            </Dialog.Title>
            <button
              onClick={() => onClose()}
              className="p-1 rounded-lg hover:bg-gray-800/50 transition-colors"
            >
              <XMarkIcon className="h-5 w-5 text-gray-400" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Title</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                placeholder="e.g., 6-month checkup"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Type</label>
                <select
                  value={formData.type}
                  onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value as any }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                >
                  <option value="checkup">Checkup</option>
                  <option value="vaccination">Vaccination</option>
                  <option value="specialist">Specialist</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">Date</label>
                <input
                  type="date"
                  value={formData.date}
                  onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                  className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Time (Optional)</label>
              <input
                type="time"
                value={formData.time}
                onChange={(e) => setFormData(prev => ({ ...prev, time: e.target.value }))}
                className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Location (Optional)</label>
              <input
                type="text"
                value={formData.location}
                onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                placeholder="Enter location"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Provider (Optional)</label>
              <input
                type="text"
                value={formData.provider}
                onChange={(e) => setFormData(prev => ({ ...prev, provider: e.target.value }))}
                className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white"
                placeholder="Healthcare provider name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Notes (Optional)</label>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                className="w-full bg-dark-bg border border-gray-800 rounded-lg px-3 py-2 text-white h-24"
                placeholder="Any additional notes..."
              />
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="completed"
                checked={formData.completed}
                onChange={(e) => setFormData(prev => ({ ...prev, completed: e.target.checked }))}
                className="rounded border-gray-800 bg-dark-bg text-accent-lavender focus:ring-accent-lavender"
              />
              <label htmlFor="completed" className="text-sm text-gray-300">
                Mark as completed
              </label>
            </div>

            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3 pt-4 sticky bottom-0 bg-dark-card border-t border-gray-800 mt-6 -mx-4 px-4 py-3 sm:static sm:border-0 sm:bg-transparent sm:px-0 sm:py-0">
              <button
                type="button"
                onClick={() => onClose()}
                className="w-full sm:w-auto px-4 py-2 rounded-lg border border-gray-800 text-gray-300 hover:bg-gray-800/50"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  const date = new Date(formData.date);
                  if (formData.time) {
                    const [hours, minutes] = formData.time.split(':');
                    date.setHours(parseInt(hours), parseInt(minutes));
                  }
                  
                  addToCalendar({
                    title: formData.title,
                    description: formData.notes,
                    startDate: date,
                    location: formData.location,
                  });
                }}
                className="w-full sm:w-auto px-4 py-2 rounded-lg bg-accent-gold/20 
                         border border-accent-gold/30 hover:bg-accent-gold/30 
                         flex items-center justify-center space-x-2"
              >
                <CalendarIcon className="h-5 w-5 text-accent-gold" />
                <span className="text-accent-gold font-medium">Add to Calendar</span>
              </button>
              <button
                type="submit"
                className="w-full sm:w-auto px-4 py-2 rounded-lg bg-accent-lavender/20 text-accent-lavender border border-accent-lavender/30 hover:bg-accent-lavender/30"
              >
                {initialData ? 'Update' : 'Save'}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default AppointmentForm; 