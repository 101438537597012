export interface VaccineSchedule {
  ageInMonths: number;
  vaccines: string[];
  notes?: string;
}

export interface VaccineDose {
  doseNumber: number;
  ageInMonths: number;
  required: boolean;
  notes?: string;
}

export interface StandardVaccine {
  id: string;
  name: string;
  description: string;
  doses: VaccineDose[];
  category: 'core' | 'recommended' | 'optional';
  protectsAgainst: string[];
}

export interface CountryVaccinationSchedule {
  countryCode: string;
  countryName: string;
  region: string;
  schedules: VaccineSchedule[];
  standardVaccines: StandardVaccine[];
  source: string;
  lastUpdated: string;
}

export const standardVaccines: StandardVaccine[] = [
  {
    id: 'dtap',
    name: 'DTaP',
    description: 'Diphtheria, Tetanus, Pertussis',
    category: 'core',
    protectsAgainst: ['Diphtheria', 'Tetanus', 'Pertussis'],
    doses: [
      { doseNumber: 1, ageInMonths: 2, required: true },
      { doseNumber: 2, ageInMonths: 4, required: true },
      { doseNumber: 3, ageInMonths: 6, required: true },
      { doseNumber: 4, ageInMonths: 15, required: true },
      { doseNumber: 5, ageInMonths: 48, required: true }
    ]
  },
  {
    id: 'ipv',
    name: 'IPV',
    description: 'Inactivated Poliovirus',
    category: 'core',
    protectsAgainst: ['Polio'],
    doses: [
      { doseNumber: 1, ageInMonths: 2, required: true },
      { doseNumber: 2, ageInMonths: 4, required: true },
      { doseNumber: 3, ageInMonths: 6, required: true },
      { doseNumber: 4, ageInMonths: 48, required: true }
    ]
  }
  // Add more standard vaccines...
];

export const vaccinationSchedules: CountryVaccinationSchedule[] = [
  {
    countryCode: 'US',
    countryName: 'United States',
    region: 'North America',
    source: 'CDC (Centers for Disease Control and Prevention)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['Hepatitis B (HepB)'],
        notes: 'First dose within 24 hours of birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['Hepatitis B (HepB)'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'Rotavirus (RV)',
          'Diphtheria, Tetanus, Pertussis (DTaP)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (PCV13)',
          'Inactivated Poliovirus (IPV)'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Rotavirus (RV)',
          'Diphtheria, Tetanus, Pertussis (DTaP)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (PCV13)',
          'Inactivated Poliovirus (IPV)'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Rotavirus (RV)',
          'Diphtheria, Tetanus, Pertussis (DTaP)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (PCV13)',
          'Inactivated Poliovirus (IPV)',
          'Influenza (Flu)',
          'Hepatitis B (HepB)'
        ],
        notes: 'Third dose of HepB, Flu vaccine recommended annually'
      },
      {
        ageInMonths: 8,
        vaccines: ['Influenza (Flu)'],
        notes: 'Second dose of flu vaccine if first time receiving flu shot'
      },
      {
        ageInMonths: 12,
        vaccines: [
          'Measles, Mumps, Rubella (MMR)',
          'Varicella',
          'Hepatitis A'
        ]
      }
    ]
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    region: 'North America',
    source: 'Public Health Agency of Canada',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['Hepatitis B'],
        notes: 'At birth if mother is HBsAg-positive'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-HB-IPV-Hib',
          'Rotavirus',
          'Pneumococcal conjugate'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-HB-IPV-Hib',
          'Rotavirus',
          'Pneumococcal conjugate'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-HB-IPV-Hib',
          'Influenza'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'Meningococcal C conjugate',
          'Pneumococcal conjugate',
          'Varicella'
        ]
      }
    ]
  },
  {
    countryCode: 'UK',
    countryName: 'United Kingdom',
    region: 'Europe',
    source: 'NHS (National Health Service)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG (if eligible)'],
        notes: 'Offered to babies at higher risk of TB'
      },
      {
        ageInMonths: 2,
        vaccines: [
          '6-in-1 vaccine (DTaP/IPV/Hib/HepB)',
          'Pneumococcal (PCV)',
          'Rotavirus',
          'Meningitis B (MenB)'
        ],
        notes: 'First doses'
      },
      {
        ageInMonths: 3,
        vaccines: [
          '6-in-1 vaccine (DTaP/IPV/Hib/HepB)',
          'Rotavirus'
        ],
        notes: 'Second doses'
      },
      {
        ageInMonths: 4,
        vaccines: [
          '6-in-1 vaccine (DTaP/IPV/Hib/HepB)',
          'Pneumococcal (PCV)',
          'Meningitis B (MenB)'
        ],
        notes: 'Third doses'
      }
    ]
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    region: 'Oceania',
    source: 'Australian Immunisation Schedule',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['Hepatitis B'],
        notes: 'Birth dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'Diphtheria, tetanus, pertussis (DTPa)',
          'Hepatitis B',
          'Polio (IPV)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (13vPCV)',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Diphtheria, tetanus, pertussis (DTPa)',
          'Hepatitis B',
          'Polio (IPV)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (13vPCV)',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Diphtheria, tetanus, pertussis (DTPa)',
          'Hepatitis B',
          'Polio (IPV)',
          'Haemophilus influenzae type b (Hib)',
          'Pneumococcal conjugate (13vPCV)',
          'Rotavirus'
        ]
      }
    ]
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    region: 'Asia',
    source: 'Universal Immunization Programme (UIP)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: [
          'BCG',
          'OPV-0',
          'Hepatitis B'
        ],
        notes: 'At birth or as early as possible till one year of age'
      },
      {
        ageInMonths: 1.5,
        vaccines: [
          'OPV-1',
          'Pentavalent-1 (DPT + HepB + Hib)',
          'Rotavirus-1',
          'PCV-1'
        ]
      },
      {
        ageInMonths: 2.5,
        vaccines: [
          'OPV-2',
          'Pentavalent-2',
          'Rotavirus-2',
          'PCV-2'
        ]
      },
      {
        ageInMonths: 3.5,
        vaccines: [
          'OPV-3',
          'Pentavalent-3',
          'Rotavirus-3',
          'PCV-3'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'OPV Booster',
          'DPT-1 Booster'
        ]
      }
    ]
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    region: 'Africa',
    source: 'National Programme on Immunization (NPI)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: [
          'BCG',
          'OPV-0',
          'Hepatitis B'
        ],
        notes: 'At birth'
      },
      {
        ageInMonths: 1.5,
        vaccines: [
          'OPV-1',
          'Pentavalent-1',
          'PCV-1',
          'Rotavirus-1'
        ]
      },
      {
        ageInMonths: 2.5,
        vaccines: [
          'OPV-2',
          'Pentavalent-2',
          'PCV-2',
          'Rotavirus-2'
        ]
      },
      {
        ageInMonths: 3.5,
        vaccines: [
          'OPV-3',
          'Pentavalent-3',
          'PCV-3'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: ['Vitamin A', 'Measles']
      }
    ]
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    region: 'Europe',
    source: 'French Ministry of Health',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'Hepatitis B'],
        notes: 'BCG recommended for at-risk infants'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate (PCV13)',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate (PCV13)',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 11,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate (PCV13)',
          'MMR'
        ]
      }
    ]
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    region: 'Europe',
    source: 'STIKO (Standing Committee on Vaccination)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 11,
        vaccines: [
          'MMR',
          'Varicella',
          'Meningococcal C'
        ]
      }
    ]
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    region: 'Asia',
    source: 'Japanese Pediatric Society',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 2,
        vaccines: [
          'Hib',
          'PCV13',
          'Rotavirus',
          'DTaP-IPV'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'Hib',
          'PCV13',
          'Rotavirus',
          'DTaP-IPV'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Hib',
          'PCV13',
          'Rotavirus',
          'DTaP-IPV',
          'BCG'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MR',
          'Varicella',
          'Hepatitis A'
        ]
      }
    ]
  },
  {
    countryCode: 'KR',
    countryName: 'South Korea',
    region: 'Asia',
    source: 'Korea Centers for Disease Control and Prevention',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['HepB'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib',
          'PCV13',
          'HepB'
        ]
      }
    ]
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    region: 'South America',
    source: 'Brazilian Ministry of Health',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'Pentavalent (DTP + Hib + HepB)',
          'VIP (Polio)',
          'Pneumococcal 10v',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Pentavalent (DTP + Hib + HepB)',
          'VIP (Polio)',
          'Pneumococcal 10v',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Pentavalent (DTP + Hib + HepB)',
          'VIP (Polio)',
          'Pneumococcal 10v'
        ]
      }
    ]
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    region: 'North America',
    source: 'Mexican National Vaccination Card (Cartilla Nacional de Vacunación)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'Hepatitis B'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'Pentavalente (DPT + HB + Hib)',
          'Rotavirus',
          'Neumocócica conjugada',
          'Polio inactivada'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Pentavalente (DPT + HB + Hib)',
          'Rotavirus',
          'Neumocócica conjugada',
          'Polio inactivada'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Pentavalente (DPT + HB + Hib)',
          'Influenza',
          'Polio inactivada'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'SRP (MMR)',
          'Neumocócica conjugada'
        ]
      }
    ]
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    region: 'Europe',
    source: 'Spanish Association of Pediatrics (AEP)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['Hepatitis B'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'Hexavalente (DTPa-VPI-Hib-HB)',
          'Neumococo conjugada',
          'Meningococo B',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Hexavalente (DTPa-VPI-Hib-HB)',
          'Neumococo conjugada',
          'Meningococo B',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 11,
        vaccines: [
          'Hexavalente (DTPa-VPI-Hib-HB)',
          'Neumococo conjugada',
          'Meningococo B',
          'Meningococo C'
        ]
      }
    ]
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    region: 'Europe',
    source: 'Italian National Vaccination Prevention Plan',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['HBV'],
        notes: 'At birth for at-risk infants'
      },
      {
        ageInMonths: 3,
        vaccines: [
          'Hexavalent (DTaP, IPV, HBV, Hib)',
          'Pneumococcal conjugate',
          'Rotavirus',
          'Meningococcal B'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'Meningococcal B'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'Hexavalent (DTaP, IPV, HBV, Hib)',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Meningococcal B'
        ]
      },
      {
        ageInMonths: 11,
        vaccines: [
          'Hexavalent (DTaP, IPV, HBV, Hib)',
          'Pneumococcal conjugate'
        ]
      }
    ]
  },
  {
    countryCode: 'RU',
    countryName: 'Russia',
    region: 'Europe',
    source: 'Russian National Immunization Schedule',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: [
          'BCG',
          'Hepatitis B'
        ],
        notes: 'First 24 hours after birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['Hepatitis B'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP',
          'Hib',
          'IPV',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4.5,
        vaccines: [
          'DTaP',
          'Hib',
          'IPV',
          'Pneumococcal conjugate',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP',
          'Hib',
          'IPV',
          'Hepatitis B'
        ]
      }
    ]
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    region: 'Asia',
    source: 'Chinese National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: [
          'BCG',
          'Hepatitis B'
        ],
        notes: 'Within 24 hours of birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['Hepatitis B'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP',
          'IPV',
          'Hib'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Hepatitis B',
          'Measles'
        ]
      }
    ]
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    region: 'Asia',
    source: 'National Childhood Immunisation Schedule (NCIS)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'Hepatitis B'],
        notes: 'At birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['Hepatitis B'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          '6-in-1 (DTaP-IPV-Hib-HepB)',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          '6-in-1 (DTaP-IPV-Hib-HepB)',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          '6-in-1 (DTaP-IPV-Hib-HepB)',
          'PCV13'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'Varicella',
          'Meningococcal ACWY'
        ]
      }
    ]
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    region: 'Oceania',
    source: 'New Zealand National Immunisation Schedule',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['Hepatitis B'],
        notes: 'At birth for babies of HBsAg-positive mothers'
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-HepB/Hib',
          'PCV10',
          'RV5'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-HepB/Hib',
          'PCV10',
          'RV5'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV-HepB/Hib',
          'PCV10',
          'RV5'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'PCV10'
        ]
      }
    ]
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    region: 'Africa',
    source: 'Expanded Programme on Immunisation (EPI)',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'OPV0'],
        notes: 'At birth'
      },
      {
        ageInMonths: 6,
        vaccines: [
          'OPV1',
          'RV1',
          'DTaP-IPV-Hib-HBV',
          'PCV13'
        ]
      },
      {
        ageInMonths: 10,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'RV2'
        ]
      },
      {
        ageInMonths: 14,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'RV3'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'Measles'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'Measles'
        ]
      }
    ]
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    region: 'Middle East',
    source: 'Israel Ministry of Health',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['HBV'],
        notes: 'At birth'
      },
      {
        ageInMonths: 1,
        vaccines: ['HBV'],
        notes: 'Second dose'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib',
          'HBV',
          'Pneumococcal'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'Varicella',
          'Hepatitis A'
        ]
      }
    ]
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    region: 'Middle East',
    source: 'UAE National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HBV'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'OPV',
          'Influenza'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'Varicella',
          'Meningococcal ACWY'
        ]
      }
    ]
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    region: 'Middle East',
    source: 'Saudi National Immunization Schedule',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HBV'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib-HBV',
          'PCV13',
          'OPV',
          'Influenza'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'MMR',
          'Varicella',
          'Meningococcal ACWY',
          'HBV'
        ]
      }
    ]
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    region: 'Europe',
    source: 'Dutch National Immunisation Programme',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['HepB'],
        notes: 'For at-risk infants'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 11,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'MMR',
          'MenC'
        ]
      }
    ]
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    region: 'Europe',
    source: 'Swedish National Vaccination Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'Pneumococcal',
          'MMR'
        ]
      }
    ]
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    region: 'Europe',
    source: 'Norwegian Childhood Immunisation Programme',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'MMR'
        ]
      }
    ]
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    region: 'Europe',
    source: 'Finnish National Vaccination Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 2,
        vaccines: ['Rotavirus']
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV10',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV10',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV10',
          'MMR'
        ]
      }
    ]
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    region: 'Europe',
    source: 'Danish Vaccination Programme',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV/Hib',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV/Hib',
          'Pneumococcal',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 12,
        vaccines: [
          'DTaP-IPV/Hib',
          'Pneumococcal',
          'MMR'
        ]
      }
    ]
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    region: 'Europe',
    source: 'Polish Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13'
        ]
      }
    ]
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    region: 'Europe/Asia',
    source: 'Turkish National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV13',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib',
          'HepB'
        ]
      }
    ]
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    region: 'Europe',
    source: 'Greek National Vaccination Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV13',
          'Rotavirus',
          'MenB'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV13',
          'Rotavirus',
          'MenB'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-IPV-Hib-HepB',
          'PCV13'
        ]
      }
    ]
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    region: 'Europe',
    source: 'Portuguese National Vaccination Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-Hib-IPV',
          'PCV13',
          'MenB',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTaP-Hib-IPV',
          'PCV13',
          'MenB',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTaP-Hib-IPV',
          'HepB'
        ]
      }
    ]
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    region: 'Asia',
    source: 'Thai National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 6,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'IPV'
        ]
      }
    ]
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    region: 'Asia',
    source: 'Malaysian National Immunisation Programme',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 5,
        vaccines: [
          'DTaP-IPV-Hib',
          'PCV'
        ]
      }
    ]
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    region: 'Asia',
    source: 'Indonesian National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['HepB', 'BCG'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DPT-HB-Hib',
          'OPV',
          'PCV'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DPT-HB-Hib',
          'OPV',
          'PCV'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DPT-HB-Hib',
          'OPV',
          'IPV'
        ]
      }
    ]
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    region: 'Asia',
    source: 'Philippine National Immunization Program',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 1.5,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'PCV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 2.5,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'PCV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 3.5,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'PCV'
        ]
      }
    ]
  },
  {
    countryCode: 'VN',
    countryName: 'Vietnam',
    region: 'Asia',
    source: 'Vietnam National Expanded Programme on Immunization',
    lastUpdated: '2024',
    standardVaccines,
    schedules: [
      {
        ageInMonths: 0,
        vaccines: ['BCG', 'HepB'],
        notes: 'At birth'
      },
      {
        ageInMonths: 2,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 3,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV',
          'Rotavirus'
        ]
      },
      {
        ageInMonths: 4,
        vaccines: [
          'DTwP-HepB-Hib',
          'OPV'
        ]
      }
    ]
  }
];

export const getAllCountries = () => {
  return vaccinationSchedules.map(schedule => ({
    code: schedule.countryCode,
    name: schedule.countryName,
    region: schedule.region
  }));
};

export const getCountrySchedule = (countryCode: string): CountryVaccinationSchedule | undefined => {
  return vaccinationSchedules.find(s => s.countryCode === countryCode);
};

// Make it a module
export {}; 