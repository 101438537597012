export function addToCalendar(event: {
  title: string;
  description?: string;
  startDate: Date;
  endDate?: Date;
  location?: string;
}) {
  // Format for Google Calendar
  const googleCalendarUrl = new URL('https://calendar.google.com/calendar/render');
  googleCalendarUrl.searchParams.append('action', 'TEMPLATE');
  googleCalendarUrl.searchParams.append('text', event.title);
  if (event.description) {
    googleCalendarUrl.searchParams.append('details', event.description);
  }
  if (event.location) {
    googleCalendarUrl.searchParams.append('location', event.location);
  }
  
  // Set start and end dates
  const startDate = event.startDate.toISOString().replace(/-|:|\.\d\d\d/g, '');
  const endDate = (event.endDate || new Date(event.startDate.getTime() + 60 * 60 * 1000))
    .toISOString().replace(/-|:|\.\d\d\d/g, '');
  
  googleCalendarUrl.searchParams.append('dates', `${startDate}/${endDate}`);

  // Open in new tab
  window.open(googleCalendarUrl.toString(), '_blank');
} 