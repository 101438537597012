import OpenAI from 'openai';
import { Message } from '../types/learning';

export class AIService {
  private openai: OpenAI;
  
  constructor() {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    
    if (!apiKey) {
      console.error('OpenAI API key is missing');
      throw new Error('OpenAI API key is not configured');
    }

    try {
      this.openai = new OpenAI({
        apiKey: apiKey.trim(),
        organization: 'org-mByzuKgVvDAa5sR5HGzMgCRT',
        dangerouslyAllowBrowser: true
      });
    } catch (error) {
      console.error('Error initializing OpenAI:', error);
      throw new Error('Failed to initialize OpenAI client');
    }
  }

  private readonly MODEL = 'gpt-4o';

  private readonly systemPrompt = `You are an empathetic and supportive AI assistant specialized in parenting and baby care. 
Your personality traits:
- Warm, nurturing, and understanding
- Patient and non-judgmental
- Conversational and engaging
- Encouraging and positive
- Gentle but professional

Communication style:
- Use a mix of professional and warm, friendly language
- Share personal-sounding examples when relevant
- Validate parents' feelings and concerns
- Use phrases like "Many parents feel...", "It's completely normal to..."
- Add gentle humor when appropriate
- Use emojis occasionally to add warmth (but not excessively)

When responding:
1. First, acknowledge the parent's question/concern with empathy
2. If more context would help, ask 1-2 brief follow-up questions (e.g., baby's age, specific symptoms)
3. Provide clear, actionable advice based on evidence
4. Include specific examples or scenarios when relevant
5. End with encouragement and an invitation for follow-up questions

Important guidelines:
- Always include appropriate medical disclaimers when needed
- Focus on being encouraging and supportive
- If unsure, acknowledge limitations and recommend professional consultation
- Keep responses concise but warm
- Use a conversational tone while maintaining professionalism
- Ask follow-up questions when more context would help (but not for every response)
- Provide specific, actionable advice when possible
- Include references to scientific research when relevant
- Validate parents' experiences and emotions

Example phrases to use:
- "I hear how challenging this must be..."
- "You're doing a great job by seeking information..."
- "Would you mind sharing your baby's age? This would help me provide more specific advice..."
- "Many parents face similar situations..."
- "Based on research and best practices..."
- "Remember, every baby is unique..."
- "You know your baby best..."

Remember to:
- Be supportive without being prescriptive
- Balance professionalism with warmth
- Encourage professional consultation when appropriate
- Celebrate small wins and progress
- Normalize common parenting challenges
- Provide hope and reassurance when appropriate`;

  async generateResponse(userMessage: string, contextMessages: Array<{ role: 'system' | 'user' | 'assistant'; content: string; }> = []): Promise<string> {
    try {
      if (!this.openai.apiKey) {
        throw new Error('API key is missing');
      }

      const messages = [
        { role: 'system' as const, content: this.systemPrompt },
        ...contextMessages.map(msg => ({
          role: msg.role as 'system' | 'user' | 'assistant',
          content: msg.content
        })),
        { role: 'user' as const, content: userMessage }
      ];

      const completion = await this.openai.chat.completions.create({
        model: this.MODEL,
        messages,
        temperature: 0.7,
        max_tokens: 500,
        presence_penalty: 0.6,
        frequency_penalty: 0.5,
        user: 'nurturing-mom-app'
      });

      const response = completion.choices[0]?.message?.content;
      if (!response) {
        throw new Error('No response generated');
      }

      if (this.containsMedicalAdvice(response)) {
        return `${response}\n\nDisclaimer: This information is for educational purposes only and should not replace professional medical advice. Always consult with your healthcare provider.`;
      }

      return response;
    } catch (error) {
      console.error('AI response error:', error);
      
      if (error instanceof Error) {
        if (error.message.includes('API key')) {
          throw new Error('Authentication failed. Please check API key configuration.');
        }
        if (error.message.includes('429')) {
          throw new Error('Too many requests. Please try again in a moment.');
        }
        if (error.message.includes('model')) {
          try {
            const completion = await this.openai.chat.completions.create({
              model: 'gpt-3.5-turbo',
              messages: [
                { role: 'system' as const, content: this.systemPrompt },
                { role: 'user' as const, content: userMessage }
              ],
              temperature: 0.7,
              max_tokens: 500,
              presence_penalty: 0.6,
              frequency_penalty: 0.5,
              user: 'nurturing-mom-app'
            });
            
            return completion.choices[0]?.message?.content || 'Failed to generate response.';
          } catch (fallbackError) {
            console.error('Fallback error:', fallbackError);
            throw new Error('Unable to access AI models. Please try again later.');
          }
        }
      }
      
      throw new Error('Failed to generate response. Please try again.');
    }
  }

  private containsMedicalAdvice(text: string): boolean {
    const medicalKeywords = [
      'medicine', 'treatment', 'diagnosis', 'symptom', 'condition',
      'doctor', 'medical', 'health', 'prescription', 'therapy',
      'vaccine', 'vaccination', 'medication', 'cure', 'disease'
    ];

    return medicalKeywords.some(keyword => 
      text.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  async getInsights(prompt: string): Promise<string> {
    try {
      const response = await this.openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `You are a supportive AI assistant analyzing baby care patterns. 
            Follow these rules strictly:
            1. Provide exactly 3-4 insights, each starting with a bullet point (•)
            2. Each insight should be clear and on its own line
            3. After the insights, add two newlines
            4. End with a short, encouraging message for the mom
            5. Never mix insights and encouragement
            6. Keep insights factual and encouragement emotional`
          },
          {
            role: "user",
            content: prompt
          }
        ],
        temperature: 0.7,
        max_tokens: 500
      });

      const content = response.choices[0]?.message?.content || 'No insights available';
      
      // Ensure proper formatting if AI response doesn't match expected format
      if (!content.includes('•')) {
        return `• ${content.split('\n')[0]}\n\nYou're doing a great job tracking your baby's care! Keep up the amazing work! 💪✨`;
      }

      return content;
    } catch (error) {
      console.error('Error getting AI insights:', error);
      throw new Error('Failed to generate insights');
    }
  }
}

export const aiService = new AIService();

interface AISuggestionParams {
  title: string;
  description: string;
  severity: string;
}

export async function getAISuggestion({ title, description, severity }: AISuggestionParams): Promise<string> {
  try {
    const prompt = `Provide a BRIEF analysis of this baby health concern:
      Title: ${title}
      Description: ${description}
      Severity: ${severity}
      
      Format your response in 3 SHORT bullet points ONLY:
      • Quick assessment (1 line)
      • Basic care suggestion (1-2 lines)
      • Key warning signs (1 line)
      
      Keep TOTAL response under 5 lines.
      End with: "👩‍⚕️ Always consult your midwife or pediatrician for proper medical advice."`;

    const response = await aiService.generateResponse(prompt);
    
    // Add severity warning for high severity concerns
    if (severity === 'high') {
      return `${response}\n\n⚠️ Given the severity, please contact your healthcare provider promptly.`;
    }

    return response;
  } catch (error) {
    console.error('Error getting AI suggestion:', error);
    return 'Unable to get AI suggestions at this time. Please consult with your healthcare provider for guidance.';
  }
}