import React, { useState } from 'react';
import { TrackingEntry } from '../../types/tracking';
import { motion } from 'framer-motion';
import { 
  ClockIcon, 
  TrashIcon, 
  PencilIcon,
  MoonIcon,
  HeartIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline';

interface Props {
  entries: TrackingEntry[];
  activeTab: 'feeding' | 'sleep' | 'diaper';
  onDelete: (entry: TrackingEntry) => void;
  onEdit: (entry: TrackingEntry) => void;
  onEntriesUpdate: () => void;
}

export function EntriesSection({ entries, activeTab, onDelete, onEdit }: Props) {
  // Add sorting state
  const [sortBy, setSortBy] = useState<'newest' | 'oldest' | 'duration'>('newest');

  // Group entries by date with sorting
  const groupedEntries = entries.reduce((groups, entry) => {
    const timestamp = typeof entry.timestamp === 'number' 
      ? entry.timestamp.toString().length === 10 
        ? entry.timestamp * 1000 
        : entry.timestamp
      : Date.now();
    
    const date = new Date(timestamp).toISOString().split('T')[0];
    
    if (!groups[date]) {
      groups[date] = [];
    }
    
    groups[date].push({
      ...entry,
      timestamp
    });
    
    // Sort entries within each group based on selected sort option
    groups[date].sort((a, b) => {
      switch (sortBy) {
        case 'oldest':
          return a.timestamp - b.timestamp;
        case 'duration':
          return (b.duration || 0) - (a.duration || 0);
        case 'newest':
        default:
          return b.timestamp - a.timestamp;
      }
    });
    
    return groups;
  }, {} as Record<string, TrackingEntry[]>);

  // Sort dates based on selected option
  const sortedDates = Object.keys(groupedEntries).sort((a, b) => 
    sortBy === 'oldest' 
      ? new Date(a).getTime() - new Date(b).getTime()
      : new Date(b).getTime() - new Date(a).getTime()
  );

  const getEntryIcon = () => {
    switch (activeTab) {
      case 'feeding': return HeartIcon;
      case 'sleep': return MoonIcon;
      case 'diaper': return ClipboardIcon;
    }
  };

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds > 0 ? `${remainingSeconds}s` : ''}`;
    }
    return `${seconds}s`;
  };

  const getEntryColor = () => {
    switch (activeTab) {
      case 'feeding': return 'text-accent-peach';
      case 'sleep': return 'text-accent-mint';
      case 'diaper': return 'text-accent-rose';
    }
  };

  const formatEntryTime = (timestamp: number) => {
    // Ensure timestamp is in milliseconds
    const adjustedTimestamp = timestamp.toString().length === 10 
      ? timestamp * 1000 
      : timestamp;

    try {
      return new Date(adjustedTimestamp).toLocaleTimeString([], { 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: true 
      });
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Invalid time';
    }
  };

  const formatEntryDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];

      if (dateStr === today) return 'Today';
      if (dateStr === yesterday) return 'Yesterday';

      return date.toLocaleDateString(undefined, { 
        weekday: 'short', 
        month: 'short', 
        day: 'numeric' 
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateStr;
    }
  };

  const formatEntryType = (entry: TrackingEntry) => {
    if (entry.feedingType) {
      switch (entry.feedingType) {
        case 'left_breast': return 'Left Breast';
        case 'right_breast': return 'Right Breast';
        case 'bottle': return 'Bottle';
        case 'solid': return 'Solid Food';
        default: return entry.feedingType;
      }
    }
    if (entry.sleepType) {
      switch (entry.sleepType) {
        case 'nap': return 'Nap';
        case 'night': return 'Night Sleep';
        default: return entry.sleepType;
      }
    }
    if (entry.diaperType) {
      switch (entry.diaperType) {
        case 'wet': return 'Wet';
        case 'dirty': return 'Dirty';
        case 'both': return 'Wet & Dirty';
        default: return entry.diaperType;
      }
    }
    return '';
  };

  const EntryIcon = getEntryIcon();
  const entryColor = getEntryColor();

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-white">Recent Entries</h3>
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as typeof sortBy)}
          className="bg-dark-bg text-white text-sm rounded-lg px-3 py-1.5 border border-gray-800 
                   focus:outline-none focus:border-accent-lavender/50"
        >
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
          {(activeTab === 'feeding' || activeTab === 'sleep') && (
            <option value="duration">Duration</option>
          )}
        </select>
      </div>
      
      {sortedDates.map(date => (
        <motion.div
          key={date}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-2"
        >
          <div className="flex items-center space-x-2">
            <div className="h-px flex-grow bg-gray-800" />
            <p className="text-sm text-gray-400">
              {formatEntryDate(date)}
            </p>
            <div className="h-px flex-grow bg-gray-800" />
          </div>

          {groupedEntries[date].sort((a, b) => b.timestamp - a.timestamp).map(entry => (
            <motion.div
              key={entry.id}
              layout
              className="bg-dark-bg rounded-lg p-3 border border-gray-800"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg ${entryColor.replace('text-', 'bg-')}/10`}>
                    <EntryIcon className={`h-5 w-5 ${entryColor}`} />
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <p className="text-white">
                        {formatEntryTime(entry.timestamp)}
                      </p>
                      <span className={`text-sm ${entryColor}`}>
                        {formatEntryType(entry)}
                      </span>
                    </div>
                    {entry.duration && (
                      <div className="flex items-center text-sm text-gray-400">
                        <ClockIcon className="h-4 w-4 mr-1" />
                        {formatDuration(entry.duration)}
                      </div>
                    )}
                    {entry.notes && (
                      <p className="text-sm text-gray-400 mt-1">{entry.notes}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => onEdit(entry)}
                    className="p-1 text-gray-400 hover:text-white rounded-lg 
                             hover:bg-gray-800 transition-colors"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => onDelete(entry)}
                    className="p-1 text-gray-400 hover:text-accent-rose rounded-lg 
                             hover:bg-accent-rose/10 transition-colors"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      ))}

      {sortedDates.length === 0 && (
        <div className="text-center py-8 text-gray-400">
          No entries yet
        </div>
      )}
    </div>
  );
}

// Add an empty export to make it a module
export {}; 