import { db, storage, auth } from './firebase';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, updateDoc, getDoc, limit, Timestamp, orderBy, writeBatch } from 'firebase/firestore';
import { TrackingEntry } from '../types/tracking';
import { WellnessEntry } from '../types/wellness';
import { HealthEntry, HealthType } from '../types/health';
import { LearningVideo, Article } from '../types/learning';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { VictoryEntry } from '../types/victory';
import { HealthConcern } from '../types/healthConcerns';
import { Appointment, Vaccination } from '../types/appointments';

interface AddArticleParams {
  title: string;
  blurb: string;
  link: string;
  category: string;
  image: File;
  imageUrl: string;
}

interface UserData {
  babyBirthDate?: number;
  // Add other user data fields as needed
}

class FirebaseStorageService {
  // Collection names
  private readonly TRACKING_COLLECTION = 'tracking';
  private readonly WELLNESS_COLLECTION = 'wellness';
  private readonly HEALTH_COLLECTION = 'health';
  private readonly VICTORIES_COLLECTION = 'victories';
  private readonly HEALTH_CONCERNS_COLLECTION = 'baby_health_concerns';
  private readonly APPOINTMENTS_COLLECTION = 'appointments';
  private readonly VACCINATIONS_COLLECTION = 'vaccinations';

  // Baby Tracking Methods
  async getEntries(type: TrackingEntry['type'], userId: string) {
    const q = query(
      collection(db, this.TRACKING_COLLECTION), 
      where('type', '==', type),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as TrackingEntry[];
  }

  async addEntry(entry: Omit<TrackingEntry, 'id'>) {
    const docRef = await addDoc(collection(db, this.TRACKING_COLLECTION), entry);
    return {
      id: docRef.id,
      ...entry
    };
  }

  async deleteEntry(entryId: string) {
    await deleteDoc(doc(db, this.TRACKING_COLLECTION, entryId));
  }

  // Health Methods
  async getHealthEntries(type: HealthType, userId: string): Promise<HealthEntry[]> {
    const q = query(
      collection(db, this.HEALTH_COLLECTION),
      where('type', '==', type),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as HealthEntry[];
  }

  async addHealthEntry(entry: Omit<HealthEntry, 'id'>): Promise<HealthEntry> {
    const docRef = await addDoc(collection(db, this.HEALTH_COLLECTION), entry);
    return {
      id: docRef.id,
      ...entry
    };
  }

  async updateHealthEntry(entry: HealthEntry): Promise<void> {
    const { id, ...data } = entry;
    await updateDoc(doc(db, this.HEALTH_COLLECTION, id), data);
  }

  async deleteHealthEntry(entryId: string, userId: string) {
    const docRef = doc(db, this.HEALTH_COLLECTION, entryId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists() || docSnap.data().userId !== userId) {
      throw new Error('Entry not found or unauthorized');
    }
    
    await deleteDoc(docRef);
  }

  // Wellness Methods
  async getWellnessEntries(type: WellnessEntry['type'], userId: string) {
    const q = query(
      collection(db, this.WELLNESS_COLLECTION),
      where('type', '==', type),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as WellnessEntry[];
  }

  async addWellnessEntry(entry: Omit<WellnessEntry, 'id'>) {
    const docRef = await addDoc(collection(db, this.WELLNESS_COLLECTION), entry);
    return {
      id: docRef.id,
      ...entry
    };
  }

  // Add to FirebaseStorageService class
  async getVideos() {
    try {
      const videoQuery = query(
        collection(db, 'videos'),
        orderBy('uploadedAt', 'desc')
      );

      const querySnapshot = await getDocs(videoQuery);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as LearningVideo[];
    } catch (error) {
      console.error('Error fetching videos:', error);
      throw error;
    }
  }

  async getDailyVideo(userId?: string) {
    const today = new Date().toISOString().split('T')[0];
    let dailyVideoQuery = query(
      collection(db, 'daily_videos'),
      where('date', '==', today),
      limit(1)
    );

    if (userId) {
      dailyVideoQuery = query(
        collection(db, 'daily_videos'),
        where('date', '==', today),
        where('userId', '==', userId),
        limit(1)
      );
    }

    const querySnapshot = await getDocs(dailyVideoQuery);
    if (querySnapshot.empty) {
      throw new Error('No video available for today');
    }

    const dailyVideo = querySnapshot.docs[0].data();
    const videoDoc = await getDoc(doc(db, 'videos', dailyVideo.videoId));

    if (!videoDoc.exists()) {
      throw new Error('Video not found');
    }

    return {
      id: videoDoc.id,
      ...videoDoc.data()
    } as LearningVideo;
  }

  async getVideoById(id: string, userId: string): Promise<LearningVideo | undefined> {
    const docRef = doc(db, 'videos', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists() || docSnap.data().userId !== userId) {
      return undefined;
    }
    
    return {
      id: docSnap.id,
      ...docSnap.data()
    } as LearningVideo;
  }

  // Add video methods
  async addVideo(data: Omit<LearningVideo, 'id'>) {
    try {
      const user = auth.currentUser;
      if (!user || user.email !== 'taiwojolaade@gmail.com') {
        throw new Error('Not authorized as admin');
      }

      const videosRef = collection(db, 'videos');
      const docRef = await addDoc(videosRef, {
        ...data,
        createdBy: user.email,
        createdAt: Timestamp.now(),
        isActive: true,
        date: Date.now()
      });

      return {
        id: docRef.id,
        ...data
      };
    } catch (error) {
      console.error('Error in addVideo:', error);
      throw error;
    }
  }

  async setDailyVideo(videoId: string) {
    const today = new Date().toISOString().split('T')[0];
    
    // First, get all daily videos for today
    const dailyVideosQuery = query(
      collection(db, 'daily_videos'),
      where('date', '==', today)
    );
    
    const querySnapshot = await getDocs(dailyVideosQuery);
    
    // Delete any existing daily videos for today
    const batch = writeBatch(db);
    querySnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // Add the new daily video
    const dailyVideoRef = doc(collection(db, 'daily_videos'));
    batch.set(dailyVideoRef, {
      videoId,
      date: today,
      setAt: Timestamp.now()
    });
    
    await batch.commit();
  }

  async deleteVideo(videoId: string) {
    try {
      const user = auth.currentUser;
      if (!user || user.email !== 'taiwojolaade@gmail.com') {
        throw new Error('Not authorized as admin');
      }

      const videoRef = doc(db, 'videos', videoId);
      const videoDoc = await getDoc(videoRef);
      
      if (videoDoc.exists()) {
        const videoData = videoDoc.data();
        
        // Delete video file from storage if URL exists
        if (videoData.videoUrl) {
          const storageRef = ref(storage, videoData.videoUrl);
          await deleteObject(storageRef).catch(err => console.log('Error deleting video file:', err));
        }
        
        // Delete thumbnail if exists
        if (videoData.thumbnailUrl) {
          const thumbnailRef = ref(storage, videoData.thumbnailUrl);
          await deleteObject(thumbnailRef).catch(err => console.log('Error deleting thumbnail:', err));
        }
      }

      await deleteDoc(videoRef);
    } catch (error) {
      console.error('Error in deleteVideo:', error);
      throw error;
    }
  }

  async updateVideo(video: LearningVideo) {
    const { id, ...data } = video;
    await updateDoc(doc(db, 'videos', id), data);
  }

  // Add these methods for articles
  private async verifyAdmin() {
    const user = auth.currentUser;
    if (!user) throw new Error('Not authenticated');
    if (user.email !== 'taiwojolaade@gmail.com') {
      throw new Error('Not authorized as admin');
    }
    await user.getIdToken(true);
    return user;
  }

  async addArticle(data: AddArticleParams) {
    try {
      const user = auth.currentUser;
      if (!user || user.email !== 'taiwojolaade@gmail.com') {
        throw new Error('Not authorized as admin');
      }

      const articlesRef = collection(db, 'articles');
      const docRef = await addDoc(articlesRef, {
        title: data.title,
        blurb: data.blurb,
        link: data.link,
        category: data.category,
        imageUrl: data.imageUrl,
        date: Date.now(),
        isActive: true,
        createdBy: user.email,
        createdAt: Timestamp.now()
      });
      return docRef.id;
    } catch (error) {
      console.error('Error in addArticle:', error);
      throw error;
    }
  }

  async getArticles() {
    try {
      const articlesRef = collection(db, 'articles');
      const q = query(articlesRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Article[];
    } catch (error) {
      console.error('Error getting articles:', error);
      throw error;
    }
  }

  async deleteArticle(id: string, imageUrl: string) {
    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    }
    await deleteDoc(doc(db, 'articles', id));
  }

  async getVictories(userId: string) {
    const q = query(
      collection(db, this.VICTORIES_COLLECTION),
      where('userId', '==', userId),
      orderBy('timestamp', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as VictoryEntry[];
  }

  async addVictory(entry: Omit<VictoryEntry, 'id'>) {
    const docRef = await addDoc(collection(db, this.VICTORIES_COLLECTION), entry);
    return {
      id: docRef.id,
      ...entry
    };
  }

  async deleteVictory(victoryId: string, userId: string) {
    const docRef = doc(db, this.VICTORIES_COLLECTION, victoryId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists() || docSnap.data().userId !== userId) {
      throw new Error('Victory not found or unauthorized');
    }
    
    await deleteDoc(docRef);
  }

  async updateVictory(victoryId: string, updates: Partial<VictoryEntry>) {
    await updateDoc(doc(db, this.VICTORIES_COLLECTION, victoryId), updates);
  }

  // Add these new methods for Wellness
  async updateWellnessEntry(entry: WellnessEntry) {
    const { id, ...data } = entry;
    await updateDoc(doc(db, this.WELLNESS_COLLECTION, id), data);
  }

  async deleteWellnessEntry(entryId: string) {
    await deleteDoc(doc(db, this.WELLNESS_COLLECTION, entryId));
  }

  async updateArticle(article: Article) {
    try {
      const user = auth.currentUser;
      if (!user || user.email !== 'taiwojolaade@gmail.com') {
        throw new Error('Not authorized as admin');
      }

      const { id, ...data } = article;
      const articleRef = doc(db, 'articles', id);
      
      await updateDoc(articleRef, {
        ...data,
        updatedAt: Timestamp.now(),
        updatedBy: user.email
      });

      return id;
    } catch (error) {
      console.error('Error in updateArticle:', error);
      throw error;
    }
  }

  async getHealthConcerns(userId: string): Promise<HealthConcern[]> {
    const q = query(
      collection(db, this.HEALTH_CONCERNS_COLLECTION),
      where('userId', '==', userId),
      orderBy('date', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as HealthConcern[];
  }

  async addHealthConcern(concern: Omit<HealthConcern, 'id'>): Promise<HealthConcern> {
    const docRef = await addDoc(collection(db, this.HEALTH_CONCERNS_COLLECTION), concern);
    return {
      id: docRef.id,
      ...concern
    };
  }

  async updateHealthConcern(concern: HealthConcern): Promise<void> {
    const { id, ...data } = concern;
    await updateDoc(doc(db, this.HEALTH_CONCERNS_COLLECTION, id), data);
  }

  async deleteHealthConcern(concernId: string): Promise<void> {
    await deleteDoc(doc(db, this.HEALTH_CONCERNS_COLLECTION, concernId));
  }

  async getAppointments(userId: string): Promise<Appointment[]> {
    const q = query(
      collection(db, this.APPOINTMENTS_COLLECTION),
      where('userId', '==', userId),
      orderBy('date', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Appointment[];
  }

  async addAppointment(appointment: Omit<Appointment, 'id'>): Promise<Appointment> {
    const docRef = await addDoc(collection(db, this.APPOINTMENTS_COLLECTION), appointment);
    return {
      id: docRef.id,
      ...appointment
    };
  }

  async updateAppointment(appointment: Appointment): Promise<void> {
    const { id, ...data } = appointment;
    await updateDoc(doc(db, this.APPOINTMENTS_COLLECTION, id), data);
  }

  async deleteAppointment(appointmentId: string): Promise<void> {
    await deleteDoc(doc(db, this.APPOINTMENTS_COLLECTION, appointmentId));
  }

  async getVaccinations(userId: string): Promise<Vaccination[]> {
    const q = query(
      collection(db, this.VACCINATIONS_COLLECTION),
      where('userId', '==', userId),
      orderBy('date', 'desc')
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Vaccination[];
  }

  async addVaccination(vaccination: Omit<Vaccination, 'id'>): Promise<Vaccination> {
    console.log('FirebaseStorage: Adding vaccination:', vaccination);
    try {
      // Remove undefined values
      const cleanData = Object.fromEntries(
        Object.entries(vaccination).filter(([_, value]) => value !== undefined)
      );
      
      const docRef = await addDoc(collection(db, this.VACCINATIONS_COLLECTION), cleanData);
      console.log('FirebaseStorage: Vaccination added with ID:', docRef.id);
      return {
        id: docRef.id,
        ...vaccination
      };
    } catch (error) {
      console.error('FirebaseStorage: Error adding vaccination:', error);
      throw error;
    }
  }

  async updateVaccination(vaccination: Vaccination): Promise<void> {
    console.log('FirebaseStorage: Updating vaccination:', vaccination);
    try {
      const { id, ...data } = vaccination;
      // Remove undefined values
      const cleanData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== undefined)
      );
      
      await updateDoc(doc(db, this.VACCINATIONS_COLLECTION, id), cleanData);
      console.log('FirebaseStorage: Vaccination updated successfully');
    } catch (error) {
      console.error('FirebaseStorage: Error updating vaccination:', error);
      throw error;
    }
  }

  async deleteVaccination(vaccinationId: string): Promise<void> {
    await deleteDoc(doc(db, this.VACCINATIONS_COLLECTION, vaccinationId));
  }

  async getUserData(userId: string): Promise<UserData | null> {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as UserData;
      }
      return null;
    } catch (error) {
      console.error('Error getting user data:', error);
      return null;
    }
  }
}

export const firebaseStorage = new FirebaseStorageService();