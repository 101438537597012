import React from 'react';
import { motion } from 'framer-motion';
import { PlayIcon, BookOpenIcon, SparklesIcon } from '@heroicons/react/24/outline';

export function LearningPreview() {
  return (
    <div className="space-y-4">
      {/* AI Chat Preview */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="flex items-center justify-between mb-3">
          <div>
            <h2 className="text-lg font-bold text-accent-gold">AI Assistant</h2>
            <p className="text-xs text-gray-400">Instant parenting guidance</p>
          </div>
          <SparklesIcon className="h-5 w-5 text-accent-gold" />
        </div>
        
        {/* Chat Messages */}
        <div className="space-y-2">
          <div className="flex justify-end">
            <div className="bg-accent-gold/10 text-white rounded-lg p-2 max-w-[80%]">
              <p className="text-xs">When should I start solid foods?</p>
            </div>
          </div>
          <div className="flex justify-start">
            <div className="bg-dark-bg border border-gray-800 rounded-lg p-2 max-w-[80%]">
              <p className="text-xs text-gray-300">
                Most babies are ready for solids between 4-6 months. Key signs include:
                • Good head control
                • Sitting with support
                • Shows interest in food
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Daily Video Section */}
      <div className="bg-dark-bg rounded-lg border border-gray-800 overflow-hidden">
        <div className="aspect-video relative">
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="p-3 rounded-full bg-accent-gold/20">
              <PlayIcon className="h-6 w-6 text-accent-gold" />
            </div>
          </div>
          <div className="absolute bottom-2 left-2 right-2">
            <h3 className="text-sm font-medium text-white">Sleep Training Tips</h3>
            <p className="text-xs text-gray-400">Expert: Dr. Sarah Johnson</p>
          </div>
        </div>
      </div>

      {/* Articles Quick Access */}
      <div className="bg-dark-bg rounded-lg p-3 border border-gray-800">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-white">Today's Articles</h3>
          <BookOpenIcon className="h-4 w-4 text-accent-gold" />
        </div>
        <div className="space-y-2">
          {[
            { title: 'Sleep Training Guide', category: 'Sleep' },
            { title: 'First Foods Guide', category: 'Nutrition' },
            { title: 'Development Milestones', category: 'Growth' }
          ].map((article, i) => (
            <div key={i} className="p-2 bg-dark-card rounded-lg border border-gray-800">
              <h4 className="text-sm text-white">{article.title}</h4>
              <div className="flex items-center justify-between mt-1">
                <span className="text-xs text-accent-gold">{article.category}</span>
                <span className="text-xs text-gray-400">Expert Guide</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-2 gap-2">
        <button className="p-2 bg-accent-gold/10 rounded-lg border border-accent-gold/20">
          <SparklesIcon className="h-4 w-4 text-accent-gold mx-auto" />
          <span className="text-xs text-accent-gold mt-1 block">Ask AI</span>
        </button>
        <button className="p-2 bg-dark-bg rounded-lg border border-gray-800">
          <BookOpenIcon className="h-4 w-4 text-gray-400 mx-auto" />
          <span className="text-xs text-gray-400 mt-1 block">Browse All</span>
        </button>
      </div>
    </div>
  );
} 